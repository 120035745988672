import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const token = Cookies.get("Token");

const DashboardContainer = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  let Navigate = useNavigate();


  const handleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!token) {
      Navigate("/login");
    }
  }, []);

  return (
    <div className="dashboard-container">
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="pt-4 px-4" style={{ overflowY: "auto" }}>
        <div className="d-flex flex-row gap-3 align-items-center">
          <button onClick={handleSidebar} className="menu-icon mb-2">
            <MenuSvg />
          </button>
          {title && <h4 className="text-start">{title}</h4>}
        </div>
        <div className="mt-4">
        {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardContainer;

const MenuSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="white"
      className="bi bi-list"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
      />
    </svg>
  );
};
