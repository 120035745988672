import React from "react";
import "./sectionfour.css";
import Card1Img from "./images/exp-sec-4-card-1.jpeg";
import Card2Img from "./images/exp-sec-4-card-2.jpeg";
import Card3Img from "./images/exp-sec-4-card-3.jpeg";
import Card4Img from "./images/exp-sec-4-card-4.jpeg";
import Card5Img from "./images/exp-sec-4-card-5.jpeg";

let cards = [
  {
    title: "Design",
    image: Card1Img,
  },
  {
    title: "Installation",
    image: Card5Img,
  },
  {
    title: "Operation and Maintenance",
    image: Card4Img,
  },
  {
    title: "Commissioning",
    image: Card3Img,
  },
  {
    title: "Project Oversight",
    image: Card2Img,
  },
];

const SectionFour = () => {
  return (
    <div className="container my-5">
      <div className="d-flex flex-column text-center gap-1">
        <h4 className="title1">Expertise</h4>
        <h1 className="section-title pb-3">
          Exceptional Expertise in Various
          <br /> Fields and Specializations
        </h1>
        <p>
          We excel in Design, Installation, Project Management, Commissioning,
          Operation, and
          <br /> Maintenance of Air Conditioning & Refrigeration equipment and
          systems.
        </p>
      </div>
      <div className="section-four-grid my-5 mt-2">
        {cards.map((i, index) => (
          <div className="section-four-card p-3 rounded" key={index}>
            <div className="card-image-div">
              <img alt="" src={i.image} />
            </div>
            <h5 className="text-white">{i.title}</h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFour;
