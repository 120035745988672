import React, { useEffect } from "react";
import Navbar from "../Navbar";
import "./Expertise.css";
import Footer from "../AboutSec/Footer.js";
import SectionOne from "./components/SectionOne.js";
import SectionTwo from "./components/SectionTwo.js";
import SectionThree from "./components/SectionThree.js";
import SectionFour from "./components/SectionFour.js";

function Expertise() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <Footer />
    </>
  );
}

export default Expertise;
