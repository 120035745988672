import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAw8m6japFidE5TAut1Y63xoLywTjvZrVI",
    authDomain: "brothesstore-1a277.firebaseapp.com",
    projectId: "brothesstore-1a277",
    storageBucket: "brothesstore-1a277.appspot.com",
    messagingSenderId: "468262766195",
    appId: "1:468262766195:web:9af09fe3e79204d4174c06",
    measurementId: "G-YNZNF8ER5Z"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };

