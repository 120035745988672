import React from "react";
import "./sectionone.css";
import Img from "./images/BACRAnime.mp4";

const SectionOne = () => {
  return (
    <div className="brands-sec-bg">
      <div className="container">
        <div className="row sec-content-container align-items-center justify-space-between">
          <div className="col-12 col-lg-6 col-xl-5 d-flex gap-2 flex-column">
            <h4 style={{ color: "#004671" }}>Value Brand Offerings</h4>
            <h1 className="text-start pb-3">
              Quality without the price tag: Value that speaks for itself.
            </h1>
            <p>
              Discover our trusted selection of HVAC brands, delivering
              exceptional quality at unbeatable prices.
            </p>
          </div>
          <div className="col-12 col-lg-6 col-xl-7">
            <div className="sec-image-container">
              {/* <img alt="" src={Img} /> */}
              <video
              controls={false}
              controlsList="nodownload"
              playsInline
              loop
              muted
              autoPlay
              style={{
                width: "100% ",
                // height: "100%",
                // marginTop:"-120px",
                backgroundColor: "transparent",
              }}
           
            >
              <source src={Img} type="video/mp4" />
            </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
