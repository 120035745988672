import React from "react";
import "./sectiontwo.css";
import CardImg1 from "./images/cards/trunkysolutions.png";
import CardImg2 from "./images/cards/operation.svg";
import CardImg3 from "./images/cards/annual.svg";
import CardImg4 from "./images/cards/config.svg";
import ArrowImage from "./images/arrow-15.svg";
import { Link, useNavigate } from "react-router-dom";

let cards = [
  {
    img: CardImg1,
    title: "Turnkey Project",
    paragraph: `Experience seamless turnkey solutions from design to commissioning, ensuring optimal performance and timely completion.`,
    path: "/trunky-solutions",
  },
  {
    img: CardImg2,
    title: "Operation & Maintenance",
    paragraph: `Ensure peak performance with our comprehensive operation and maintenance services, designed to keep your systems running smoothly and efficiently.`,
    path: "/operation-maintenance",
  },
  {
    img: CardImg3,
    title: "Energy Optimization",
    paragraph: `Maximize efficiency with our energy optimization services, designed to reduce costs and enhance performance.`,
    path: "/annual-maintenance",
  },
  {
    img: CardImg4,
    title: "Troubleshooting, Programming and Configuration",
    paragraph: `Get expert troubleshooting, programming, and configuration services to ensure your systems run flawlessly and efficiently.`,
    path: "/troubleshooting-&-config",
  },
];

const SectionTwo = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="my-5">
        <div className="container">
          <div className="sec-two-grid">
            {cards?.map((item, index) => {
              return (
                <div
                  className="rounded p-4 item-card"
                  style={{ cursor: "pointer" }}
                  key={index}
                  onClick={() => {
                    navigate(item?.path);
                  }}
                >
                    <img className="card-image" alt="" src={item?.img} />
                  <div className="d-flex flex-column gap-2">
                    <h5 className="">{item?.title}</h5>
                    <div className="">{item.paragraph}</div>
                  </div>

                  <div className="_learn_more_sec mt-2 d-flex flex-row gap-3 align-items-center">
                    <Link
                      className="learn-more-text text-decoration-none"
                      to={item?.path}
                    >
                      Learn More
                    </Link>
                    <img className="arrow-image" alt="" src={ArrowImage} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionTwo;
