import React, { useEffect } from "react";
import { plusIcon } from "./routes";
import BrandsTable from "./BrandsTable";
import { useNavigate } from "react-router-dom";
import { GetRequest } from "../Actions/Api_Requests";
import { useDispatch, useSelector } from "react-redux";
import { setCards } from "../../Redux/reducers/DataSlice";
import DashboardContainer from "./DashboardContainer";
import swal from "sweetalert";

const BrandOffering = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let { cards } = useSelector((state) => state.DataSlice);

  let fetchCards = async () => {
    try {
      let res = await GetRequest("/api/cards");
      dispatch(setCards(res?.data?.cards));
    } catch (error) {
      swal("Opps...", "Something went wrong, while retriving data.")
    }
  };

  useEffect(() => {
    if (!cards?.length) {
      fetchCards();
    }
  }, []);

  const addNewBrand = () => {
    navigate("/dashboard/add-card");
  };

  return (
    <>
      <DashboardContainer>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <h4 className="text-start">Value Brand Offerings in BAC</h4>
          <button
            onClick={addNewBrand}
            className="dash-button rounded px-3 text-white d-flex  bg-steelblue align-items-center gap-1"
          >
            {plusIcon()}
            <span>New Brand</span>
          </button>
        </div>
        <div className="mt-3">
          <BrandsTable data={cards} />
        </div>
      </DashboardContainer>
    </>
  );
};

export default BrandOffering;
