import React from "react";

import "./_why_choose_us.css";
import ExpertiesStyledCard from "./ExpertiesStyledCard";

const WhyChooseUs = ({ title, title2, paragraph, cards }) => {
  return (
    <>
      <div className="container my-lg-5 py-5">
        <div className="my-5 py-3 text-center" data-aos='fade-up'>
          <h2>{title ? title : "Why Choose BAC?"}</h2>
        </div>

        <div className="_why_choose_grid_2">
          <ExpertiesStyledCard title={title2} paragraph={paragraph} />

          <div className="_cards_grid">
            {cards?.map((item, index) => {
              return (
                <div className="" key={index} data-aos="zoom-out">
                  <h5>{item?.title}</h5>
                  <p>{item?.paragraph}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyChooseUs;
