import React, { useEffect } from "react";
import Footer from "../AboutSec/Footer.js";
import NavigateBack from "../ExpertiseInner/NavigateBack.js";
import PageDescription from "../Exp_inner_Components/PageDescription.js";
import Section3 from "./Section3.js";
import PageHeroSection from "../Exp_inner_Components/PageHeroSection.js";

function NewAndUsedChillers() {
  useEffect(() => {
    document.body.classList.add("menu-is-closed");
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavigateBack />
      <PageHeroSection
        parentStyles={" _rentail_main "}
        title={"Importer New and Used Chillers"}
        description={`We provide professional Industrial process cooling solutions as
                per specific requirement of the process machinery.`}
      />
      <PageDescription
        headingStyles={" col-lg-8"}
        title={
          "Welcome to BAC, your premier destination for top-quality chillers and HVACR solutions."
        }
        description={
          " As a leading importer with a wealth of experience and expertise, we take pride in delivering excellence in every aspect of our service."
        }
      />
      <Section3 />
      <Footer />
    </>
  );
}

export default NewAndUsedChillers;
