import React from "react";
import { useNavigate } from "react-router-dom";

function NavigateBack() {
  const navigate = useNavigate();
  const back = () => {
    navigate(-1);
  };
  return (
    <>
      <div
        data-aos="fade-right"
        className="bg-primary arrow-back"
        onClick={back}
      >
        <i className="fa-solid fa-arrow-left"></i>
      </div>
    </>
  );
}

export default NavigateBack;
