import React from "react";
import "./css/About.css";
import img1 from "./images/aaaa.webp";
import img2 from "./images/802A0068.webp";

function About2() {
  return (
    <div className="container about2-conatiner">
      <div className="row align-items-center">
        <div
          className="col-lg-6 col-sm-12 col-md-12 d-flex"
          data-aos="fade-right"
        >
          <div className="about1_L">
            <h1 className="fw-bolder text-start">
              We are Motivated by the Desire to Achieve
            </h1>
            <p className="mt-3 ">
              Brothers Air Conditioning leads the industry in professional HVACR
              services in Pakistan, serving a diverse range of commercial and
              industrial clients. Our dedicated team of highly competent
              professionals is committed to meeting various HVACR needs
              efficiently.
            </p>
          </div>
        </div>
        <div
          className="col-lg-6 col-sm-12 col-md-10 about3_R"
          data-aos="fade-left"
        >
          <img src={img2} alt="" />
        </div>
      </div>

      <div className="row align-items-center">
        <div
          className="col-lg-6 col-sm-12 col-md-10 about3_R"
          data-aos="fade-left"
        >
          <img src={img1} alt="" />
        </div>
        <div
          className="col-lg-6 col-sm-12 col-md-12 d-flex justify-content-start align-items-center flex-column about-R3-main"
          data-aos="fade-right"
        >
          <h1 className="fw-bolder">Why Choose Us</h1>
          <ul className="ul">
            <li>
              We specialize in supplying original equipment manufacturer (OEM)
              spare parts for all HVACR brands in the Pakistani market, ensuring
              reliability and compatibility.
            </li>
            <li>
              As a proud supplier of both new and used chillers, we meticulously
              inspect and test each unit sourced from Europe to deliver fully
              operational and high-quality chillers to our clients.
            </li>
            <li>
              We are the exclusive distributor of several HVACR brands, giving
              you access to the best products and technologies in the industry.
            </li>
            <li>
              We excel in providing turnkey project solutions that cater to a
              wide spectrum of needs, from general HVACR systems to specialized
              applications across various industries.
            </li>
            <li>
              Our capabilities include overhauls, enhancements, upgrades, and
              retrofits of centrifugal, screw, and reciprocating chillers.
            </li>
            <li>
              From importation to on-site commissioning, our process ensures the
              highest quality standards, guaranteeing top performance and
              customer satisfaction.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default About2;
