import React, { useEffect } from "react";
import NavigateBack from "../ExpertiseInner/NavigateBack";
import PageHeroSection from "../Exp_inner_Components/PageHeroSection";
import PageDescription from "../Exp_inner_Components/PageDescription";
import Section2 from "./Section2";
import WhyChooseUs4Grid from "../Exp_inner_Components/WhyChooseUs4Grid";
import Footer from "../AboutSec/Footer";
import "./processcooling.css"

let cards1 = [
    {
      title: "Innovative Solutions",
      paragraph: `We continuously invest in research and development to stay ahead of industry trends and technological advancements, enabling us to deliver cutting-edge cooling solutions. `,
    },
    {
      title: "Collaborative Approach",
      paragraph: `At BAC, we believe in collaboration and partnership. We work closely with our clients to understand their unique cooling requirements and provide tailored solutions that exceed expectations.`,
    },
    {
      title: "Proven Track Record",
      paragraph: `With a proven track record of success and a portfolio of satisfied clients across various industries, BAC is the trusted choice for process cooling solutions worldwide.
      `,
    },
    {
      title: "Commitment to Excellence",
      paragraph: `Our dedication to excellence drives everything we do, from product design and manufacturing to customer service and support. When you choose BAC, you can trust that you're getting the best-in-class solutions.`,
    },
  ];

const ProcessCoolingSolution = () => {
  useEffect(() => {
    document.body.classList.add("menu-is-closed");
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavigateBack />

      <PageHeroSection
        parentStyles={"process_cooling_main"}
        title={`Process Cooling Solution`}
        description={`We provide Efficient Operation and Maintenance Solutions for Seamless HVACR Performance..`}
      />

      <PageDescription
        title={"Welcome to BAC Process Cooling Solutions"}
        description={
          "At BAC, we understand the critical role that efficient process cooling plays in the HVACR (Heating, Ventilation, Air Conditioning, and Refrigeration) industry. With decades of experience and a commitment to innovation, we specialize in delivering top-notch solutions for process cooling needs, particularly in the realm of chillers and associated equipment."
        }
      />

      <Section2/>
      <WhyChooseUs4Grid cards={cards1} />

      <Footer/>

    </>
  );
};

export default ProcessCoolingSolution;
