import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  pt: 4,
  px: 4,
  pb: 4,
};

function ProjectDetailsModel({ item, id }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center mb-4">
        <div
          className={`bg-dark d-flex justify-centent-center align-items-center position-relative _bac_project_card`}
          style={{ width: "100%", height: "18rem" }}
          onClick={handleOpen}
        >
          <img alt="" className="_project_img_container" src={item.img} />
          <div className="_project_title d-flex justify-content-center align-items-center flex-column ">
            <h5 className="text-white text-center ">{item?.name}</h5>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }} className={"_project_detail_model"}>
          <div className="">
            <h4 id="child-modal-title">{item?.name}</h4>
          </div>
          <div className="mt-3 d-flex flex-column gap-3">
            <div className="">
              <h6 id="child-modal-title">Client: </h6>
              <span>{item?.nodes?.client}</span>
            </div>

            <div className="">
              <h6 id="child-modal-title">Location:</h6>
              <div className="d-flex flex-column">
                {item?.nodes?.location?.map((loc, locId) => (
                  <span key={locId}>{loc}</span>
                ))}
              </div>
            </div>

            <div className="">
              <h6 id="child-modal-title">Project Name:</h6>
              <span>{item?.nodes?.project_name}</span>
            </div>

            <div className="">
              <h6 id="child-modal-title">Nature of Job: </h6>
              <span>{item?.nodes?.job_nature}</span>
            </div>

            <div className="">
              <h6 id="child-modal-title">Brands:</h6>
              <div className="d-flex flex-column">
                {item?.nodes?.brand?.map((brand, locId) => (
                  <span key={locId}>{brand}</span>
                ))}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default ProjectDetailsModel;
