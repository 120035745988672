import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../AboutSec/Footer";
import Header from "./ReuseableComponents/Header";
import Img1 from "./ProjectImages/napa.png";
import Img2 from "./ProjectImages/johsoncontrols.jpeg";
import Img3 from "./ProjectImages/albaraka.jpeg";
import Img4 from "./ProjectImages/aseptic.png";
import Img5 from "./ProjectImages/tufail.png";
import Img6 from "./ProjectImages/pso.jpeg";
import Img7 from "./ProjectImages/regent.jpg";
import Img8 from "./ProjectImages/ugarit.png";
import Img9 from "./ProjectImages/union.png";
import Img10 from "./ProjectImages/zarghun.png";
import Img11 from "./ProjectImages/hashim.jpeg";
import Img12 from "./ProjectImages/qasim.jpg";
import ProjectDetailsModel from "./ReuseableComponents/ProjectDetailsModel";

let projects = [
  {
    img: Img1,
    name: "Zia Mohyeddin Theatre HVAC System Project",
    nodes: {
      client: "NAPA",
      location: ["Karachi, Pakistan"],
      project_name: "Zia Mohyeddin Theatre Project",
      job_nature:
        "From design to commissioning, Brothers Air Conditioning delivered a seamless and efficient HVAC system for Zia Mohyeddin Theatre.",
      brand: ["York YLAA  0457 HE (120 Tons)"],
    },
  },

  {
    img: Img3,
    name: "Al Baraka",
    nodes: {
      client: "Al Baraka",
      location: ["Karachi, Pakistan"],
      project_name: "Al Baraka Project",
      job_nature: "Supply & Commissioning with Warranty",
      brand: ["Carrier 30 XA 0252 (70 Tons)"],
    },
  },
  {
    img: Img4,
    name: "Popular Aseptic Packaging Pvt. Ltd.",
    nodes: {
      client: "Popular Aseptic Packaging Pvt. Ltd.",
      location: ["Nooriabad, Pakistan"],
      project_name: "Davis Plant Project",
      job_nature:
        "Our job included importing, supplying, and commissioning used Trane and Carrier water-cooled chillers, AHUs, panels, and VFDs for an efficient air conditioning and process cooling system.",
      brand: [
        "Trane RTHD Water-Cooled Screw Chiller (400 Tons X 2)",
        "Trane CVGF Water-Cooled Centrifugal Chiller (550 Tons)",
        "Carrier 30 HXC 120 Chiller (120 Tons)",
        "Carrier 30 HXC 090 Chiller (90 tons)",
      ],
    },
  },
  {
    img: Img5,
    name: "Tufail Chemicals",
    nodes: {
      client: "Tufail Chemicals",
      location: ["Karachi, Pakistan"],
      project_name: "Surfactants Plant Project",
      job_nature:
        "We supplied and commissioned a used Carrier water-cooled chiller, highlighting our commitment to cost-effective, sustainable solutions.",
      brand: ["Carrier 30 HXC 090 (90 Tons)"],
    },
  },
  {
    img: Img6,
    name: "PSO House",
    nodes: {
      client: "PSO House",
      location: ["Karachi, Pakistan"],
      project_name: "PSO House Project",
      job_nature: "Operation and Maintenance",
      brand: ["YT and YK water-cooled centrifugal chiller"],
    },
  },
  {
    img: Img7,
    name: "Regent Plaza",
    nodes: {
      client: "Regent Plaza",
      location: ["Karachi, Pakistan"],
      project_name: "Regent Plaza Project",
      job_nature: "Supply and Commissioning with warranty",
      brand: ["Carrier 30 HXC 310 (310 Tons)"],
    },
  },
  {
    img: Img11,
    name: "Hashim Medical City Hospital",
    nodes: {
      client: "Hashim Medical City Hospital",
      location: ["Hyderabad, Pakistan"],
      project_name: "Hashim Medical Project",
      job_nature: "Supply and Commissioning with Warranty",
      brand: ["Carrier 30 HXC 120 (120 Tons)"],
    },
  },
  {
    img: Img12,
    name: "Qasim Banquet",
    nodes: {
      client: "Qasim Banquet",
      location: ["Hyderabad, Pakistan"],
      project_name: "Qasim Banquet Project",
      job_nature: "From Designing to commission all jobs were done by the company.",
      brand: ["30 GX 365 Air Cooled Screw Chiller","York YVAA 1215 Air Cooled Chiller"],
    },
  },
];

function NationPorject() {
  const [nationState, setNationState] = useState(false);
  const navigate = useNavigate();
  const ToNation_Porject = () => {
    navigate("/Projects/NationWide");
  };

  useEffect(() => {
    setNationState(true);
  }, []);
  const To_oversease_pROJECT = () => {
    navigate("/Projects/OverseasePorject");
  };
  const To_Client_projects = () => {
    navigate("/Projects/ClientProject");
  };
  const toBack = () => {
    navigate("/Projects");
  };
  const toDevis = () => {
    navigate("/Projects/DevisPlant");
  };
  const toPSO = () => {
    navigate("/Projects/PSO");
  };
  const toTufail = () => {
    navigate("/Projects/NationWide/TufailCehmiacls");
  };

  const tozIA = () => {
    navigate("/Projects/NationWide/Zia Mohyeddin Theatre");
  };
  return (
    <>
      <Navbar />
      <Header />
      {/* =============================== */}
      <div className="container proj-conatiner ">
        <div className="row">
          <div className="col-12">
            <div className="btn-main-div">
              <div className="d-flex justify-content-center ">
                <button
                  className="btn-gal nation"
                  style={{ backgroundColor: "white", color: "black" }}
                  onClick={toBack}
                >
                  All Projects
                </button>

                <button
                  className={`btn-gal ${nationState === true ? "active1" : ""}`}
                  onClick={ToNation_Porject}
                >
                  Nation Wide Projects
                </button>
                <button className="btn-gal " onClick={To_oversease_pROJECT}>
                  Overseas Projects
                </button>
                <button className="btn-gal " onClick={To_Client_projects}>
                  Clients{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ============================== */}
      <div className="container  my-5 ">
        <div className="row  my-5">
          {projects.map((item, index) => (
            <ProjectDetailsModel item={item} key={index} id={index} />
          ))}

          {/* <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center "
            onClick={tozIA}
          >
            <div
              className="bg-dark galler1  gal-na-1 position-relative "
              data-aos="fade-right"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">
                  Zia Moheuddin Theatre
                </h5>
               
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center"
            onClick={toDevis}
          >
            <div
              className="bg-dark galler3 gal3 gal-nat-3 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center w-75">
                  {" "}
                  Popular Aseptic Packaging Private Ltd.
                </h5>
               
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center  "
            data-aos="fade-right"
            onClick={toTufail}
          >
            <div
              className="galler3 gal-4 gal-nat-4 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">Tufail Chemicals</h5>
              
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center  "
            data-aos="fade-right"
            onClick={toPSO}
          >
            <div
              className="bg-dark galler3 gal-7 gal-nat-7 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">PSO House</h5>
               
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center  "
            data-aos="fade-right"
          >
            <div
              className="bg-dark galler3 gal-7  AL-ABARKA  gal-nat-8 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">Al Barak </h5>
               
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center  "
            data-aos="fade-right"
          >
            <div
              className="bg-dark galler3 gal-7   gal-nat-9 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">Regent Plaza</h5>
                
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center  "
            data-aos="fade-right"
          >
            <div
              className="bg-dark galler3 gal-7  hashi gal-nat-10 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column">
                <h5 className="text-white text-center ">Hasim Medical</h5>
                
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default NationPorject;
