import React from "react";
import CardImg1 from "./images/card-img-1.webp";
import CardImg2 from "./images/card-img-2.webp";
import CardImg3 from "./images/card-img-3.webp";
import CardImg4 from "./images/card-img-4.webp";
import ImgTextGrid from "../Exp_inner_Components/ImgTextGrid";

let cards = [
  {
    img: CardImg1,
    imgLeft: true,
    paragraph: `Quality is at the heart of everything we do. That's why we exclusively deal in OEM (Original Equipment Manufacturer) products, ensuring that each component we provide meets the highest standards of performance, reliability, and durability. By choosing OEM products, you can rest assured that your equipment will continue.`,
  },
  {
    img: CardImg2,
    imgLeft: false,
    paragraph: `One of the keys to our success is our commitment to maintaining a vast inventory of both new and used spare parts. This extensive stockpile allows us to fulfill customer requirements promptly, getting you the parts you need exactly when you need them. Whether you're facing an unexpected breakdown or simply planning.
    `,
  },
  {
    img: CardImg3,
    imgLeft: true,
    paragraph: `Our team of procurement experts is dedicated to providing personalized service and support, guiding you through every step of the purchasing process. From identifying the right parts for your specific chiller model to ensuring seamless delivery, we're here to make your spare parts procurement experience as smooth and hassle-free as possible.
    `,
  },
  {
    img: CardImg4,
    imgLeft: false,
    paragraph: `When it comes to ensuring the longevity and efficiency of your HVACR systems, don't settle for anything less than the best. Trust BAC Spare Parts Procurement to deliver the quality, reliability, and convenience you need to keep your operations running smoothly. Get in touch with us today and experience the BAC difference firsthand.
    `,
  },
];

const Section2 = () => {
  return (
    <div className="container my-5">
      <div className="d-flex flex-column" style={{ gap: "5rem" }}>
        {cards?.map((item, index) => {
          return <ImgTextGrid key={index} item={item} />;
        })}
      </div>
    </div>
  );
};

export default Section2;
