import React, { useEffect, useState } from "react";
import DashboardContainer from "./DashboardContainer";
import Plus from "../Images/+.png";
import { useNavigate } from "react-router-dom";
import { collection, deleteDoc, doc, getDocs } from "firebase/firestore";
import { db, storage } from "../../config/firebaseconfig";
import { RotatingLines } from "react-loader-spinner";
import { deleteObject, ref } from "firebase/storage";
import { TextField } from "@mui/material";
import { plusIcon } from "./routes";

const BacStore = () => {
  const navigte = useNavigate();

  const handleAddProduct = () => {
    navigte("/dashboard/add-product");
  };

  const [delId, setDelId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [search, setSearch] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, "storeData"));
      const fetchedData = [];
      querySnapshot.forEach((doc) => {
        fetchedData.push({ id: doc.id, ...doc.data() });
      });
      setData(fetchedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error?.message);
    }
  };

  useEffect(() => {
    !data?.length && fetchData();
  }, []);

  let deleteImage = async (item) => {
    const filename = item
      .substring(item.lastIndexOf("/") + 1)
      .split("?")[0];

    const storageRef = ref(storage, filename);
    await deleteObject(storageRef);
  }

  const handleDeleteProduct = async (id) => {
    try {
      setDelId(id);
      setIsDeleting(true);

      let docRef = doc(db, "storeData", id);
      await deleteDoc(docRef);

      let card = data.find((item) => item.id === id);

      const uploadPromises = card?.images.map(item => deleteImage(item));

      await Promise.all(uploadPromises);

      setDelId(null);
      setIsDeleting(false);
      let UpdatedData = data?.filter((item) => item.id !== id);
      setData(UpdatedData);
    } catch (error) {
      setIsDeleting(false);
      alert(error?.message);
    }
  };

  const handleEdit = (id) => {
    navigte(`/dashboard/edit-product/${id}`)
  }

  let getDate = (item) => {
    let date = new Date(item?.lastModified?.seconds * 1000 + item?.lastModified?.nanoseconds / 1000000);
    let a = date?.getDate();
    let b = date?.getMonth() + 1;
    let c = date?.getFullYear();
    return `${a}/${b}/${c}`
  }

  return (
    <DashboardContainer title={"BAC Store"}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className="d-flex flex-row align-items-center justify-content-between flex-wrap gap-2">
            <div className="col-12 col-sm-10 col-md-8 col-lg-5">
              <TextField
                focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                required

                variant="outlined"
                placeholder="Search Product..."
                values={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />

            </div>
              {
                data?.length > 8 ?
                  <button
                    onClick={handleAddProduct}
                    className="dash-button rounded px-3 text-white d-flex  bg-steelblue align-items-center gap-1"
                  >
                    {plusIcon()}
                    <span>Add Product</span>
                  </button> : ""
              }
          </div>

          <div className="employees-container my-4">
            {data?.filter((item) => {
              if (item?.productName?.toLowerCase().includes(search.toLowerCase())) {
                return item
              } else if (item?.model?.toLowerCase().includes(search.toLowerCase())) {
                return item
              } else if (item?.brandName?.toLowerCase().includes(search.toLowerCase())) {
                return item
              } else if (item?.part_no?.toLowerCase().includes(search.toLowerCase())) {
                return item
              }

            })?.map((item, index) => (
              <div className="store-product-card rounded" key={index}>
                <button
                  onClick={() => {
                    handleDeleteProduct(item?.id);
                  }}
                  className="store-product-del-btn border"
                >
                  {isDeleting && delId === item?.id ? (
                    <RotatingLines
                      visible={true}
                      height="20"
                      width="20"
                      color="white"
                      strokeColor="black"
                      strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperClass=""
                    />
                  ) : (
                    <DeleteSvg />
                  )}
                </button>
                <button
                  onClick={() => {
                    handleEdit(item.id)
                  }}
                  className="store-product-edit-btn border"
                >
                  <EditButton />
                </button>
                <div className="store-product-card-img-container">
                  {item?.images?.length ? <img src={item?.images[0]} className="" alt="..." /> : ""}
                </div>

                <div className="p-3 d-flex flex-column w-100">

                  <div className="d-flex flex-row align-items-center justify-content-between gap-2">

                    <h6 className="">{item?.productName}</h6>

                    {item?.inStock ? (
                      <div
                        className=""
                        style={{ color: "green", fontSize: "13px", fontWeight: "500", minWidth: "60px" }}
                      >
                        In Stock
                      </div>
                    ) : item?.onOrder ? (
                      <div
                        style={{ color: "green", fontSize: "13px", fontWeight: "500", minWidth: "60px" }}
                      >
                        On Order Only
                      </div>
                    ) : ""}
                  </div>


                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <span style={{ fontSize: "14px" }}>Brand</span>
                    <h6 className="">{item?.brandName}</h6>
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <span style={{ fontSize: "14px" }}>Model</span>
                    <h6 className="">{item?.model}</h6>
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <span style={{ fontSize: "14px" }}>Part Number</span>
                    <h6 className="">{item?.part_no}</h6>
                  </div>
                  {item?.inStock ? <div className="d-flex flex-row align-items-center justify-content-between">
                    <span style={{ fontSize: "14px" }}>Quantity</span>
                    <h6 className="px-2 py-1 rounded text-black">{item?.quantity}</h6>
                  </div> : ""}

                  <div className="d-flex flex-row align-items-center justify-content-between gap-3">
                    <span style={{ fontSize: "14px" }}>Price</span>
                    <h6 className="text-end" style={{ fontSize: "15px" }}>{item?.price}</h6>
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <span style={{ fontSize: "13px" }}>Last Modified</span>
                    <span className="" style={{ fontSize: "13px" }}>{getDate(item)}</span>
                  </div>
                </div>
              </div>
            ))}

            <div
              className="shadow store-product-add rounded bg-steelblue"
              onClick={handleAddProduct}
            >
              <img src={Plus} class="card-img-top" alt="..." />
              <div class="pt-3">
                <h2 className="text-center text-white">BAC Store</h2>
              </div>
            </div>
          </div>
        </>

      )}
    </DashboardContainer>
  );
};

const LoadingIndicator = () => {
  return (
    <div
      style={{ height: "60vh" }}
      className="w-100 d-flex align-items-center justify-content-center"
    >
      <RotatingLines
        visible={true}
        height="40"
        width="40"
        color="white"
        strokeColor="black"
        strokeWidth="5"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
};

const DeleteSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-trash3"
      viewBox="0 0 16 16"
    >
      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
    </svg>
  );
};

const EditButton = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
    </svg>
  )
}

export default BacStore;
