import React, { useEffect, useState } from "react";
import "./css/SliderAbout.css";
import { GetRequest } from "../../Admin/Actions/Api_Requests";

function SliderAbout() {
  const [docs, setDocs] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const fetchDocs = async () => {
    try {
      let res = await GetRequest("/api/docs");
      setDocs(res.data.docs);
    } catch (error) {}
  };

  useEffect(() => {
    if (!docs.length) {
      fetchDocs();
    }
  }, []);

  return (
    <>
      {isOpen && imageUrl ? (
        <div className="_certificate_model">
          <div className="_certificate_img_container">
            <div className="_certificate_model_overlay" />
            <img alt="" src={imageUrl} />

            <span
              className="certificate_model_close_btn"
              onClick={() => {
                setIsOpen(!isOpen);
                setImageUrl(null);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
              </svg>
            </span>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="container  mt-5">
        <div className="row">
          <div className="col-lg-12 d-flex slider-about  justify-content-center align-items-center mt-5">
            <div className="w-75 d-flex  justify-content-center align-items-center flex-column ">
              <h1 className="fw-bolder text-center">
                Discover a Legacy of Excellence with Our Certified Achievements
              </h1>
              <p className="w-75 text-center ">
                Brothers Air Conditioning holds industry-recognized
                certificates, validating our commitment to excellence in HVACR
                services. We bring a blend of certified expertise and quality
                service to elevate your comfort and performance standards.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container  my-5">
        <div className="certificaties-grid-about my-5">
          {docs?.map((item, index) => (
            <div
              key={index}
              className="rounded certificatie-card"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIsOpen(!isOpen);
                setImageUrl(item?.path);
              }}
            >
              <img alt="" src={item?.path} />
              <div className="certificatie-name-overlay">
                <h6 className="text-center px-4">{item?.name}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SliderAbout;
