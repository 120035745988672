import React from "react";
import CardImg1 from "./images/card-img-1.webp";
import CardImg2 from "./images/card-img-2.webp";
import CardImg3 from "./images/card-img-3.webp";
import CardImg4 from "./images/card-img-4.webp";
import CardImg5 from "./images/card-img-5.webp";
import ImgTextGrid from "../Exp_inner_Components/ImgTextGrid";

let cards = [
  {
    img: CardImg1,
    imgLeft: true,
    title: "Advanced Technology Integration",
    paragraph: `Our team harnesses the power of advanced technologies to design and implement cooling solutions that maximize efficiency and minimize energy consumption. From state-of-the-art chillers to innovative control systems, we leverage the latest advancements to deliver superior performance and cost savings.
        `,
  },
  {
    img: CardImg2,
    imgLeft: false,
    title: "Customized Design and Engineering",
    paragraph: `Understanding that every project is unique, we offer customized design and engineering services to meet specific requirements and challenges. Whether it's a large-scale commercial facility or a smaller residential space, our experts work closely with clients to develop tailored solutions that optimize comfort and functionality `,
  },
  {
    img: CardImg3,
    imgLeft: true,
    title: "Comprehensive Maintenance and Support",
    paragraph: `We believe that ongoing maintenance is crucial for ensuring the longevity and performance of cooling equipment. That's why we offer comprehensive maintenance programs designed to keep systems running smoothly and efficiently. Our team of skilled technicians provides timely inspections, preventive maintenance, and responsive support to address any issues promptly.
    `,
  },
  {
    img: CardImg4,
    imgLeft: false,
    title: "Energy Efficiency and Sustainability",
    paragraph: `At BAC, we are committed to sustainability and environmental responsibility. Our cooling solutions are designed to minimize environmental impact while maximizing energy efficiency. By utilizing innovative technologies and eco-friendly practices, we help clients reduce their carbon footprint and achieve their sustainability goals.
    `,
  },
  {
    img: CardImg5,
    imgLeft: true,
    title: "Exceptional Customer Service",
    paragraph: `We prioritize customer satisfaction above all else, striving to exceed expectations at every step of the process. From initial consultation to ongoing support, our dedicated team is committed to delivering exceptional service and ensuring a seamless experience for our clients.
    `,
  },
];

const Section2 = () => {
  return (
    <div className="container my-5">
      <div className="my-5 py-5 text-center">
        <h2>Our Expertise</h2>
      </div>

      <div className="d-flex flex-column" style={{ gap: "5rem" }}>
        {cards?.map((item, index) => {
          return <ImgTextGrid key={index} item={item} />;
        })}
      </div>
    </div>
  );
};

export default Section2;
