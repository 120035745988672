import React from "react";
import "./sectionthree.css";
import Card1Img from "./images/cards/comfort.svg";
import Card2Img from "./images/cards/process.svg";
import Card4Img from "./images/cards/chillers.svg";
import Card6Img from "./images/cards/oem.svg";
import { Link, useNavigate } from "react-router-dom";

let cards = [
  {
    title: "Comfort Cooling",
    image: Card1Img,
    des: "Our comprehensive comfort cooling solutions designed for residential, commercial, and institutional applications ensure a comfortable and healthy indoor environment.",
    path: "/comfort-cooling",
  },
  {
    title: "Process Cooling",
    image: Card2Img,
    des: "Maintain optimal conditions for your industrial processes and equipment with our specialized process cooling systems.",
    path: "/process-cooling-solutions",
  },
  // {
  //   title: "Cold Chain Solution",
  //   image: Card3Img,
  //   des: "Integrated control solutions for chillers, encompass programmable controllers compatible with the most widely used building management systems.",
  //   path: "/cold-chain-solution",
  // },
  {
    title: "Import New & Used Chillers",
    image: Card4Img,
    des: "We offer a wide range of high-quality new and used chillers to meet your specific cooling needs, ensuring both cost-efficiency and reliability.",
    path: "/new-used-chillers",
  },
  // {
  //   title: "Consultancy",
  //   image: Card5Img,
  //   des: "Enhance chiller plant performance through energy-efficient solutions and comprehensive optimization services.",
  //   path: "/consultancy",
  // },
  {
    title: "Spare Parts (OEM)",
    image: Card6Img,
    des: "Keep your HVAC systems running smoothly with our extensive inventory of OEM spare parts and components.",
    path: "/spare-parts-procurement",
  },
];

const SectionThree = () => {
  const navigate = useNavigate();

  return (
    <div className="section-parent mt-5 py-5">
      <div className="container">
        <h1 className="section-title">Where Service Meets Satisfaction.</h1>
        <div className="sec-three-grid mt-5">
          {cards?.map((item, index) => (
            <div
              className="p-4 shadow rounded section-3-card"
              style={{ cursor: "pointer" }}
              // onClick={() => {
              //   navigate(item?.path);
              // }}
              key={index}
            >
              <div className="" />
              <img className="card-image" alt="" src={item?.image} />
              <h5 className="">{item?.title}</h5>
              <p className="">
                {item?.des?.length > 120
                  ? `${item?.des?.slice(0, 120)}...`
                  : item?.des}
              </p>
              {/* <Link
                to={item?.path}
                className="mt-3 card-footer text-decoration-none"
              >
                <span className="text-white">Learn More</span>
                <ArrowRightIcon />
              </Link> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ArrowRightIcon = () => {
  return (
    <svg
      width="25"
      className="arrow-right-icon"
      height="16"
      viewBox="0 0 27 16"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5665 8.96687C26.957 8.57635 26.957 7.94318 26.5665 7.55266L20.2025 1.1887C19.812 0.798173 19.1788 0.798173 18.7883 1.1887C18.3978 1.57922 18.3978 2.21239 18.7883 2.60291L24.4452 8.25977L18.7883 13.9166C18.3978 14.3071 18.3978 14.9403 18.7883 15.3308C19.1788 15.7214 19.812 15.7214 20.2025 15.3308L26.5665 8.96687ZM0.859375 9.25977H25.8594V7.25977H0.859375V9.25977Z"
        fill=""
      />
    </svg>
  );
};

export default SectionThree;
