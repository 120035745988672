import React, { useState } from "react";
import DashboardContainer from "./DashboardContainer";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { PostRequestTest } from "../Actions/Api_Requests";
import { useDispatch } from "react-redux";
import { addCard } from "../../Redux/reducers/DataSlice";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { ImageSvg } from "../BrandsComponent/EditChildForm";

const label = { inputProps: { "aria-label": "Switch demo" } };

const AddCard = () => {
  const [cardImg, setCardImg] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [formErrors, setFormErrors] = useState({
    image: null,
    bgImg: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultValues = {
    name: "",
    description: "",
    // location: "",
    website_url: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues });

  const handleImageChange = (e) => {
    setCardImg(e.target.files[0]);
  };

  const handleBgOnchange = (e) => {
    setBackgroundImage(e.target.files[0]);
  };

  const onSubmit = async (e) => {
    try {
      if (!cardImg) {
        setFormErrors({ ...formErrors, image: "Image is required." });
        return;
      }

      if (!backgroundImage) {
        setFormErrors({
          ...formErrors,
          bgImg: "Background Image is required.",
        });
        return;
      }

      setLoading(true);
      let payload = {
        ...e,
        logo: cardImg,
        bg_img: backgroundImage,
        authorized,
      };
      let res = await PostRequestTest("/api/cards/create", payload, "multi");
      setLoading(false);

      dispatch(addCard(res.data.card));
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  };

  return (
    <DashboardContainer title={"Card Details"}>
      <div className="">
        <div className="d-flex flex-row flex-wrap align-items-center gap-4">
          <label
            htmlFor="card-1-img"
            className="d-flex flex-column align-items-center justify-content-center"
            style={{
              width: "160px",
              overflow: "hidden",
              borderRadius: "5px",
              height: "120px",
              background: cardImg ? "white" : "#ccc",
            }}
          >
            <input
              onChange={handleImageChange}
              style={{ display: "none" }}
              type="file"
              name="card-1-img"
              required
              id="card-1-img"
            />
            {cardImg && (
              <img
                alt=""
                className="border"
                src={URL.createObjectURL(cardImg)}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
              />
            )}

            {!cardImg && (
              <div className="px-3 text-center">
                <ImageSvg />
                <br />
                <span className="text-white">Brand Logo</span>
              </div>
            )}
          </label>
        </div>

        <div className="d-flex flex-row flex-wrap align-items-center gap-4 mt-3">
          <label
            htmlFor="card-1-bg"
            className="d-flex flex-column align-items-center justify-content-center"
            style={{
              width: "50%",
              overflow: "hidden",
              borderRadius: "5px",
              height: "200px",
              background: backgroundImage ? "white" : "#ccc",
            }}
          >
            <input
              onChange={handleBgOnchange}
              style={{ display: "none" }}
              type="file"
              name="card-1-bg"
              required
              id="card-1-bg"
            />
            {backgroundImage && (
              <img
                alt=""
                className="border"
                src={URL.createObjectURL(backgroundImage)}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
              />
            )}

            {!backgroundImage && (
              <>
                <ImageSvg />
                <span className="text-white">Brand Background Image</span>
              </>
            )}
          </label>
        </div>

        {formErrors?.bgImg && (
          <span style={{ fontSize: "12px" }} className="text-danger">
            {formErrors?.bgImg}
          </span>
        )}
        <div className="mt-5 mb-3">
          <form className="" onSubmit={handleSubmit(onSubmit)}>
            {/* main card inputs  */}
            <div className="edit-employee-grid gap-4">
              <div className="gap-4">
                <TextField
                  {...register("name", { required: "Name is required" })}
                  ref={register("name").ref}
                  multiline
                  rows={1}
                  focused
                  id="outlined-basic"
                  fullWidth
                  label="Name"
                  aria-errormessage={errors?.name?.message}
                  error={errors?.name?.message}
                  variant="outlined"
                />
                {/* <TextField
                  {...register("location", {
                    required: "Location is required",
                  })}
                  ref={register("location").ref}
                  multiline
                  rows={1}
                  error={errors?.location?.message}
                  aria-errormessage={errors?.location?.message}
                  focused
                  id="outlined-basic"
                  fullWidth
                  label="Location"
                  variant="outlined"
                /> */}

                <TextField
                  {...register("website_url", {
                    required: "Website url is required",
                  })}
                  ref={register("website_url").ref}
                  multiline
                  type="url"
                  rows={1}
                  error={errors?.website_url?.message}
                  aria-errormessage={errors?.website_url?.message}
                  focused
                  id="outlined-basic"
                  fullWidth
                  label="Website Url"
                  variant="outlined"
                />

                <label
                  htmlFor="autherized"
                  className="d-flex flex-row gap-2 align-items-center"
                >
                  <span>Autherized</span>
                  <Switch
                    onChange={() => {
                      setAuthorized(!authorized);
                    }}
                    defaultChecked={authorized}
                    id="autherized"
                    title="Autherized"
                    {...label}
                  />
                </label>
              </div>
              <div className="">
                <TextField
                  ref={register("description").ref}
                  {...register("description", {
                    required: "Description is required.",
                    minLength: {
                      value: 120,
                      message: "Description should be min 100 characters.",
                    },
                  })}
                  focused
                  id="outlined-basic"
                  fullWidth
                  multiline
                  error={errors?.description?.message}
                  aria-errormessage={errors?.description?.message}
                  rows={5}
                  label="Description"
                  variant="outlined"
                />
              </div>
            </div>

            <button
              type="button"
              onClick={() => {
                navigate("/dashboard");
              }}
              className="align-self-start mt-4 px-4 dash-button dash-button-rounded bg-steelblue mx-2"
            >
              Cancel
            </button>

            <button
              type="submit"
              className="align-self-start mt-4 px-4 dash-button dash-button-rounded bg-steelblue"
            >
              {loading ? (
                <RotatingLines
                  visible={true}
                  height="20"
                  width="20"
                  color="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default AddCard;
