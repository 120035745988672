import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    boxShadow: 24,
    outline: "none",
    borderRadius: "8px",
    overflow: "hidden"
};

const imgParent = {
    width: "100%",
    height: "300px",
    background: "#f5f5f5",
    overflow: "hidden",
    position: "relative"
}

const ProductGallery = ({ images, isOpen, setIsOpen, company, product, ePrice, model, part_no, inStock, onOrder, quantity }) => {

    const [currentIndex, setCurrentIndex] = React.useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <Modal
            open={isOpen}
            onClose={() => { setIsOpen(!isOpen); setCurrentIndex(0) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div style={imgParent}>

                    <div className='_product_images_length'>
                        {currentIndex+1}/{images?.length}
                    </div>

                    {
                        images?.length ?
                            <img src={images[currentIndex]} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                            : ""
                    }

                    <svg onClick={handlePrev} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-left cursor-pointer _preview_product_btn" style={{ cursor: "pointer" }} viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                    </svg>

                    <svg onClick={handleNext} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right cursor-pointer _next_product_btn" style={{ cursor: "pointer" }} viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg>



                </div>

                <div className=''>
                    <div className="p-3 d-flex flex-column w-100">
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <h5 className="">{product}</h5>
                            {inStock ? (
                                <span
                                    style={{ color: "green", fontSize: "14px", fontWeight: "500" }}
                                >
                                    In Stock
                                </span>
                            ) : onOrder ? (
                                <span
                                    style={{ color: "green", fontSize: "14px", fontWeight: "500" }}
                                >
                                    On Order Only
                                </span>
                            ) : ""}
                        </div>

                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <span style={{ fontSize: "14px" }}>Brand</span>
                            <h6 className="">{company}</h6>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <span style={{ fontSize: "14px" }}>Model Number</span>
                            <h6 className="px-2 py-1 rounded text-black">{model}</h6>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <span style={{ fontSize: "14px" }}>Part Number</span>
                            <h6 className="px-2 py-1 rounded text-black">{part_no}</h6>
                        </div>
                        {inStock ? <div className="d-flex flex-row align-items-center justify-content-between">
                            <span style={{ fontSize: "14px" }}>Quantity</span>
                            <h6 className="px-2 py-1 rounded text-black">{quantity}</h6>
                        </div> : ""}
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <span style={{ fontSize: "14px" }}>Price</span>
                            <h6 className="bg-steelblue px-2 py-1 rounded text-white">
                                {ePrice}
                            </h6>
                        </div>
                    </div>
                </div>

            </Box>
        </Modal>
    )
}

export default ProductGallery;