import React from "react";
import CardImg1 from "./images/card-img-1.webp";
import CardImg2 from "./images/card-img-2.webp";
import CardImg3 from "./images/card-img-3.webp";
import CardImg4 from "./images/card-img-4.webp";
import CardImg5 from "./images/card-img-5.webp";
import ImgTextGrid from "../Exp_inner_Components/ImgTextGrid";

let cards = [
  {
    img: CardImg1,
    imgLeft: true,
    title: "Efficiency Enhancement",
    paragraph: `Maximize your energy efficiency with our tailored optimization solutions. We analyze your systems and processes to identify areas for improvement, helping you reduce energy consumption and lower costs.`,
  },
  {
    img: CardImg2,
    imgLeft: false,
    title: "Customized Strategies",
    paragraph: `Our team develops customized strategies to optimize energy usage based on your unique needs and objectives. Whether it's upgrading equipment, implementing controls, or improving workflows, we design solutions that deliver tangible results.`,
  },
  {
    img: CardImg3,
    imgLeft: true,
    title: "Sustainable Practices",
    paragraph: `We're committed to promoting sustainability and environmental responsibility. Our energy optimization solutions prioritize the use of renewable resources, energy-efficient technologies, and eco-friendly practices to minimize your carbon footprint.`,
  },
  {
    img: CardImg4,
    imgLeft: false,
    title: "Cost Savings",
    paragraph: `By optimizing energy usage, you'll not only reduce your environmental impact but also enjoy significant cost savings. Our solutions are designed to maximize your return on investment, helping you achieve long-term financial benefits.`,
  },
  {
    img: CardImg5,
    imgLeft: true,
    title: "Ongoing Support",
    paragraph: `Our services don't end with implementation. We provide ongoing support and monitoring to ensure that your energy optimization strategies continue to deliver results over time. We're dedicated to helping you maintain peak performance and efficiency.`,
  },
];

const Section2 = () => {
  return (
    <div className="container my-5">
      <div className="my-5 py-5 text-center">
        <h2>Our Expertise</h2>
      </div>

      <div className="d-flex flex-column" style={{ gap: "5rem" }}>
        {cards?.map((item, index) => {
          return <ImgTextGrid key={index} item={item} />;
        })}
      </div>
    </div>
  );
};

export default Section2;
