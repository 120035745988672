import React from "react";
import "./sectionone.css";
import Img from "./images/exp-img-1.webp";

const SectionOne = () => {
  return (
    <div className="section-bg">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 col-md-7  d-flex flex-column gap-3">
            <h3 className="title1">Expertise</h3>
            <h1 className="title2 pb-3 text-start">
              Discover Our Diverse Range of Expertise.
            </h1>
            <p className="paragraph">
              Unlock the potential of your project with our expertise and
              innovation. From inception to execution, we offer personalized
              solutions that redefine industry standards. Explore our
              comprehensive services and let us shape success together
            </p>
          </div>

          <div className="col-12 col-lg-6 col-md-5  image-container">
            <img className="sectionImage" alt="" src={Img} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
