import React, { useEffect } from "react";
import Section2 from "./Section2";
import Section3 from "./Section3";
import NavigateBack from "../ExpertiseInner/NavigateBack";
import PageDescription from "../Exp_inner_Components/PageDescription";
import Footer from "../AboutSec/Footer";
import WhyChooseUs4Grid from "../Exp_inner_Components/WhyChooseUs4Grid";
import PageHeroSection from "../Exp_inner_Components/PageHeroSection";
import "./consultancy.css"

let cards1 = [
  {
    title: "Technical Expertise",
    paragraph: `Our team of experienced engineers and technicians brings a wealth of technical expertise to every project. We leverage our knowledge and skills to deliver innovative solutions that meet your specific requirements and exceed your expectations.`,
  },
  {
    title: "Customized Approach",
    paragraph: `We understand that every project is unique. That's why we take a customized approach to our consultancy services, tailoring our solutions to meet your specific needs, budget, and objectives.
    `,
  },
  {
    title: " Sustainability",
    paragraph: `We're committed to helping our clients minimize their environmental footprint and maximize energy efficiency. From energy audits to green building certifications, we offer a range of sustainability-focused services to support your goals.
    `,
  },
  {
    title: "Customer Focus",
    paragraph: `At BAC, customer satisfaction is our top priority. We pride ourselves on our responsiveness, reliability, and dedication to meeting our clients' needs. When you choose BAC, you can trust that you're partnering with a team that's invested in your success.
    `,
  },
];

function ConsultancyMain() {
  useEffect(() => {
    document.body.classList.add("menu-is-closed");
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavigateBack />

      <PageHeroSection
        parentStyles={"_consultancy_main"}
        title={`Consultancy`}
        description={`We provide professional Industrial process cooling solutions
      as per specific requirement of the process machinery.`}
      />
      <PageDescription
        headingStyles={" col-lg-9"}
        title={
          "Welcome to BAC HVACR Consultancy: Your Premier Partner for Projects, Supply, and Services!"
        }
        description={`BAC stands at the forefront of the HVACR (Heating, Ventilation, Air Conditioning, and Refrigeration) industry, offering a comprehensive suite of consultancy services that encompass projects, supply, and services. With a proven track record of excellence, technical prowess, and a commitment to client satisfaction, we're your trusted ally for all your HVACR needs.`}
      />
      <Section2 />
      <WhyChooseUs4Grid cards={cards1} />
      <Section3 />
      <Footer />
    </>
  );
}

export default ConsultancyMain;
