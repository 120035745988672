import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  employees: [],
  certificates: [],
  cards: [],
};

const DataSlice = createSlice({
  name: "DataSlice",
  initialState,
  reducers: {
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
    addEmployee: (state, action) => {
      state.employees = [action.payload, ...state.employees];
    },
    deleteEmployee: (state, action) => {
      let updatedEmployees = state.employees.filter(
        (item) => item._id !== action.payload
      );
      state.employees = updatedEmployees;
    },
    updateEmployee: (state, action) => {
      let current = state.employees.filter(
        (item) => item._id !== action.payload._id
      );
      state.employees = [action.payload, ...current];
    },
    setDocs: (state, action) => {
      state.certificates = action.payload;
    },
    addDoc: (state, action) => {
      state.certificates = [action.payload, ...state.certificates];
    },
    updateDoc: (state, action) => {
      let updatedDocs = state.certificates.filter(
        (item) => item._id !== action.payload._id
      );
      state.certificates = [{ ...action.payload }, ...updatedDocs];
    },
    deleteDoc: (state, action) => {
      let updatedDocs = state.certificates.filter(
        (item) => item._id !== action.payload
      );
      state.certificates = updatedDocs;
    },
    setCards: (state, action) => {
      state.cards = action.payload;
    },
    addCard: (state, action) => {
      state.cards = [action.payload, ...state.cards];
    },

    updatedParentCard: (state, action) => {
      let removeCard = state.cards.filter(
        (item) => item._id !== action.payload.cardId
      );
      state.cards = [action.payload.card, ...removeCard];
    },

    addChildCard: (state, action) => {
      let cardToUpdate = state.cards.find(
        (item) => item._id === action.payload.parent
      );
      let removedCard = state.cards.filter(
        (item) => item._id !== action.payload.parent
      );

      cardToUpdate?.items.push(action.payload.child);

      state.cards = [cardToUpdate, ...removedCard];
    },
    updateChildCard: (state, action) => {
      let card = state.cards.find((item) => item._id === action.payload.parent);

      let updatedCards = card?.items?.filter(
        (item) => item._id !== action.payload.child
      );

      card.items = [action.payload.card, ...updatedCards];

      let current = state.cards.filter(
        (item) => item._id !== action.payload.parent
      );
      state.cards = [card, ...current];
    },
    deleteParentCard: (state, action) => {
      state.cards = state.cards.filter((item) => item._id !== action.payload);
    },
    deleteChildCard: (state, action) => {
      let card = state.cards.find((item) => item._id === action.payload.parent);
      let updatedCards = card?.items?.filter(
        (item) => item._id !== action.payload.child
      );
      card.items = updatedCards;

      let current = state.cards.filter(
        (item) => item._id !== action.payload.parent
      );
      state.cards = [card, ...current];
    },
    deleteAsset: (state, action) => {
      let updatedAssets = state.assets.filter(
        (item) => item._id !== action.payload
      );
      state.assets = updatedAssets;
    },
  },
});

export const {
  setEmployees,
  addEmployee,
  deleteEmployee,
  updateEmployee,
  setDocs,
  deleteDoc,
  addDoc,
  updateDoc,
  setCards,
  addCard,
  deleteAsset,
  addChildCard,
  updateChildCard,
  deleteParentCard,
  deleteChildCard,
  updatedParentCard,
} = DataSlice.actions;
export default DataSlice.reducer;
