import React, { Fragment, useEffect, useState } from "react";
import ino from "../../MenuImages/Ambassador.gif";
import programing from "../../MenuImages/Programming (1).gif";
import pipe from "../../MenuImages/Pipeline maintenance.gif";
import haza from "../../MenuImages/Hazardous waste.gif";
import innova from "../../MenuImages/Innovation.gif";
import contac from "../../MenuImages/Contact us (2).gif";
import { NavLink } from "react-router-dom";
import Hamburger from "hamburger-react";

const Menu = () => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });

    if (isOpen) {
      document.body.classList.add("menu-is-open");
    } else {
      document.body.classList.remove("menu-is-open");
    }

    return () => {
      document.body.classList.remove("menu-is-closed");
    };
  }, [isOpen]);

  return (
    <Fragment>
      <div className="humburger_upper">
        <Hamburger
          color="white"
          rounded
          size={20}
          toggled={isOpen}
          toggle={setOpen}
        />
      </div>
      {isOpen ? (
        <div
          className={`
        w-100 main-menu-container menu_open `}
        >
          <div className="humburger_navinner z-index-10   m-2 ">
            <Hamburger
              rounded
              color="white"
              size={25}
              toggled={isOpen}
              toggle={setOpen}
            />
          </div>

          <div className="container-fluid nav-container overflow-hidden">
            <div className="row">
              <div className="col-lg-4 col-sm1-12 col-md-12  nav-main g-dark mb-3">
                <div className="wrapper h-100 w-100">
                  <div className="card front-face">
                    <div>
                      <h2>About Us</h2>
                    </div>
                    <img alt="" src={programing} />
                  </div>
                  <div className="card back-face">
                    <div className="info d-flex justify-content-center align-items-center flex-column">
                      <div className="_menu_title_inner_face">
                        <span>About Us</span>
                      </div>
                      <p className="w-50 pt-3 text-center">
                        Read about our journey and commitment to excellence
                      </p>
                    </div>
                    <main className="main">
                      <NavLink className={"navLin"} to={"/About"}>
                        <button className="btn">About Us</button>
                      </NavLink>
                    </main>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm1-12 col-md-12  nav-main g-dark mb-3">
                <div className="wrapper h-100 w-100">
                  <div className="card front-face">
                    <div>
                      <h2>Brands</h2>
                    </div>
                    <img alt="" src={innova} />
                  </div>
                  <div className="card back-face">
                    <div className="info d-flex justify-content-center align-items-center flex-column">
                      <div className="title text-center">Brands</div>
                      <p className="w-50 pt-3 text-center">
                      Explore HVAC brands, delivering unparalleled quality and value.
                      </p>
                    </div>
                    <main className="main">
                      <NavLink className={"navLin"} to={"/Brands"}>
                        <button className="btn">Brands</button>
                      </NavLink>
                    </main>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm1-12 col-md-12  nav-main g-dark mb-3">
                <div className="wrapper h-100 w-100">
                  <div className="card front-face">
                    <div>
                      <h2>BAC Store</h2>
                    </div>
                    <img alt="" src={haza} />
                  </div>
                  <div className="card back-face">
                    <div className="info d-flex justify-content-center align-items-center flex-column">
                      <div className="title">BAC Store</div>
                      <p className="w-50 pt-3 text-center">
                      Discover the best HVAC OEM Spare Parts and Equipment—click to enter our store and explore
                      </p>
                    </div>
                    <main className="main">
                      <NavLink className={"navLin"} to={"/BAC_Store"}>
                        <button className="btn">Products </button>
                      </NavLink>
                    </main>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm1-12 col-md-12  nav-main mb-3">
                <div className="wrapper h-100 w-100">
                  <div className="card front-face">
                    <div>
                      <h2 className="">Clients & Projects</h2>
                    </div>
                    <img alt="" src={ino} />
                  </div>
                  <div className="card back-face">
                    <div className="info d-flex justify-content-center align-items-center flex-column">
                      <div className="title fw-bplder">Clients & Projects</div>
                      <p className="w-50 pt-3 text-center">
                        See our impactful ventures and transformative endeavors.
                      </p>
                    </div>
                    <main className="main">
                      <NavLink className={"navLin"} to={"/Projects"}>
                        <button className="btn">Clients & Projects </button>
                      </NavLink>
                    </main>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm1-12 col-md-12  nav-main g-dark mb-3">
                <div className="wrapper h-100 w-100">
                  <div className="card front-face">
                    <div>
                      <h2>Expertise & Offerings</h2>
                    </div>
                    <img alt="" src={pipe} />
                  </div>
                  <div className="card back-face">
                    <div className="info d-flex justify-content-center align-items-center flex-column">
                      <div className="title">Expertise & Offerings</div>

                      <p className="w-50 pt-3 text-center">
                        Explore our industry know-how and specialized skills.
                      </p>
                    </div>
                    <main className="main">
                      <NavLink className={"navLin"} to={"/Expertise"}>
                        <button className="btn">Expertise & Offerings</button>
                      </NavLink>
                    </main>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm1-12 col-md-12  nav-main g-dark mb-3">
                <div className="wrapper h-100 w-100">
                  <div className="card front-face">
                    <div>
                      <h2>Contact us</h2>
                    </div>
                    <img alt="" src={contac} />
                  </div>
                  <div className="card back-face">
                    <div className="info d-flex justify-content-center align-items-center flex-column">
                      <div className="title">Contact Us</div>
                      <p className="w-50 pt-3 text-center">
                        Connect with us through the given contact information
                      </p>
                    </div>
                    <main className="main">
                      <NavLink className={"navLin"} to={"/Contact"}>
                        <button className="btn">Contact Us</button>
                      </NavLink>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Menu;
