import React, { useEffect } from "react";
import "./comfortcooling.css";
import NavigateBack from "../ExpertiseInner/NavigateBack";
import PageDescription from "../Exp_inner_Components/PageDescription";
import Footer from "../AboutSec/Footer";
import Section2 from "./Section2";
import WhyChooseUs4Grid from "../Exp_inner_Components/WhyChooseUs4Grid";
import PageHeroSection from "../Exp_inner_Components/PageHeroSection";

let cards1 = [
  {
    title: "Proven Track Record",
    paragraph: `With decades of experience in the industry, BAC has earned a reputation for excellence and reliability.`,
  },
  {
    title: "Innovative Solutions",
    paragraph: `We stay at the forefront of technological advancements to offer innovative solutions that deliver superior performance and efficiency.
      `,
  },
  {
    title: " Centric Approach",
    paragraph: `Our customer-centric approach ensures that we understand and prioritize the unique needs of each client, delivering tailored solutions that exceed expectations.
      `,
  },
  {
    title: "Sustainability",
    paragraph: `We are dedicated to promoting sustainability and environmental stewardship through our energy-efficient and eco-friendly cooling solutions.`,
  },
];

const ComfortCooling = () => {
  useEffect(() => {
    document.body.classList.add("menu-is-closed");
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavigateBack />
      <PageHeroSection
        parentStyles={"comfort_cooling_main"}
        title={`Comfort Cooling Solutions`}
        description={`We provide professional Industrial process cooling solutions
      as per specific requirement of the process machinery.`}
      />

      <PageDescription
        title={"Welcome to BAC Comfort Cooling Solutions"}
        description={
          "At BAC, we specialize in delivering cutting-edge Comfort Cooling solutions for HVACR chillers and equipment, ensuring optimal performance and enhanced comfort in diverse environments. With a legacy of excellence and innovation spanning decades, we are committed to providing reliable, efficient, and sustainable cooling solutions tailored to meet the unique needs of our clients."
        }
      />
      <Section2 />
      <WhyChooseUs4Grid cards={cards1} />
      <Footer />
    </>
  );
};

export default ComfortCooling;
