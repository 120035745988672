import React from "react";
import img1 from "./images/section2/Clip path group.svg";
import img2 from "./images/section2/Group 3626.svg";
import img3 from "./images/section2/Group (3).svg";
import img4 from "./images/section2/Group (4).svg";
import img5 from "./images/section2/Group (8).svg";
import img6 from "./images/section2/Group (9).svg";

let cards = [
  {
    img: img1,
    title: "Innovation",
    paragraph: `Join a company that values creativity and forward-thinking
        solutions in the HVAC industry.`,
  },
  {
    img: img2,
    title: "Career Growth",
    paragraph: `Experience opportunities for professional development and
        skill enhancement to advance your career.`,
  },
  {
    img: img3,
    title: "Commitment to Excellence",
    paragraph: `Be a part of a team dedicated to delivering top-notch
        products and services, ensuring quality in every aspect.`,
  },
  {
    img: img4,
    title: "Dynamic Team Environment",
    paragraph: `Collaborate with passionate professionals who work together
        to overcome challenges and achieve common goals.`,
  },
  {
    img: img5,
    title: "Shape the Future",
    paragraph: `Contribute to cutting-edge developments in air conditioning
        solutions, playing a role in shaping the industry's future
        at BAC.`,
  },
  {
    img: img6,
    title: "Global Impact",
    paragraph: `Become a part of a team that contributes to a positive
    global impact through sustainable and innovative HVACR
    solutions.`,
  },
];

const Section2 = () => {
  return (
    <>
      <div className="container my-5">
        <div className="row">
          <div className="col-12 career2 my-5 text-center  d-flex justify-content-center align-items-center -primary flex-column">
            <h3>Career</h3>
            <h1 className="text-center">Experience a Workplace that Values Your Wellbeing</h1>
            <p className=" -warning ">
            At BACR, we prioritize your overall health and happiness. Enjoy a fulfilling career where professional success meets personal satisfaction.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row caree-div">
          {cards?.map((item, index) => {
            return (
              <div
                key={index}
                className="col-lg-6 col-sm-12  d-flex justify-content-end align-items-center"
              >
                <div className=" w-100  d-flex justify-content-end align-items-center ">
                  <div className=" icon-main d-flex justify-content-around  ">
                    <div className="mx-3">
                      <img alt="" src={item.img} />
                    </div>
                    <div className="  w-75">
                      <h2 className="fw-bolder">{item.title}</h2>
                      <p className="w-75">{item.paragraph}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Section2;
