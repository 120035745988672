import React, { useEffect, useState } from "react";
import "./sectiontwo.css";
import Tick from "./images/tick.png";
import { NavLink, useNavigate } from "react-router-dom";
import { GetRequest } from "../../../Admin/Actions/Api_Requests";
import { RotatingLines } from "react-loader-spinner";
import { CiLocationOn } from "react-icons/ci";
const SectionTwo = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();

  const fetchCards = async () => {
    setLoading(true)
    try {
      let res = await GetRequest("/api/cards");
      setCards(res.data.cards);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (!cards.length) {
      fetchCards();
    }
  }, []);

  return (
    <div className="container">
      {loading ? (
        <div
          style={{ height: "100vh" }}
          className="w-100 d-flex flex-row align-items-center justify-content-center"
        >
          <RotatingLines
            visible={true}
            height="96"
            width="96"
            color="grey"
            strokeColor="blue"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{
              color: "blue",
            }}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="sec-cards-grid my-5">
          {cards?.map((item, index) => (
            <div
              key={index}
              className="d-flex flex-column sec-card-item rounded p-3 gap-2"
              onClick={() => {
                navigation(`/Brand/${item?.name}`);
              }}
            >
              {item?.authorized ? (
                <div className="sec-auth-label mb-1">
                  <img alt="" src={Tick} />
                  <span>Authorized Dealer </span>
                </div>
              ) : (
                <div style={{height:"22px", width:"22px"}}/>
              )}
              <div className="sec-comp-img-container mx-3">
                <img alt="" className="rounded" src={item?.image?.path} />
              </div>
              {/* <div className="d-flex flex-row align-items-center gap-2 py-2">
                <img alt="alt" src={Location} className="location-icon" />
                <CiLocationOn className="location-icon"/>
                <span className="text-bold">{item?.location}</span>
              </div> */}
              <p>
                {item?.description?.length > 100 ? <>{item?.description?.slice(0,100)}...</>:item?.description}
              </p>
              <NavLink
                to={`/Brand/${item?._id}`}
                className="text-decoration-none d-flex flex-row align-items-center justify-content-end sec-card-footer gap-3"
              >
                <span>Learn more</span>
                <svg
                  width="27"
                  className="arrow-right-icon"
                  height="16"
                  viewBox="0 0 27 16"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.5665 8.96687C26.957 8.57635 26.957 7.94318 26.5665 7.55266L20.2025 1.1887C19.812 0.798173 19.1788 0.798173 18.7883 1.1887C18.3978 1.57922 18.3978 2.21239 18.7883 2.60291L24.4452 8.25977L18.7883 13.9166C18.3978 14.3071 18.3978 14.9403 18.7883 15.3308C19.1788 15.7214 19.812 15.7214 20.2025 15.3308L26.5665 8.96687ZM0.859375 9.25977H25.8594V7.25977H0.859375V9.25977Z"
                    fill=""
                  />
                </svg>
              </NavLink>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SectionTwo;
