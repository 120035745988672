import React from "react";
import "./_why_choose_us.css";

const ExpertiesStyledCard = ({title, paragraph}) => {
  return (
    <div className="rounded experties_styled_card" data-aos='zoom-out'>
      <div className="text-center mx-5 rounded py-3 _title_container">
        <h5>{title}</h5>
      </div>
      <div className="px-4 pb-2 px-md-5 pb-md-4 pt-md-1">
        <p>{paragraph}</p>
      </div>
    </div>
  );
};

export default ExpertiesStyledCard;
