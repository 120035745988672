"use server";

import axios from "axios";
import Cookies from "js-cookie";
const token = Cookies.get("Token");

export let api = "https://bacr-backend.onrender.com";
export let apiDev = "https://bacr-backend.onrender.com";

const GetRequest = async (end_point) => {
  return await axios.get(`${api}${end_point}`);
};


const CheckAuthState = async (end_point, token) => {
  return await axios.get(`${apiDev}${end_point}`, {headers:{Authorization:token}});
};

const PostRequest = async (end_point, data, dataType) => {
  return await axios.post(`${api}${end_point}`, data, {
    headers: {
      Authorization: token,
      "Content-Type":
        dataType === "multi" ? "multipart/form-data" : "Application/json",
    },
  });
};

const PostRequestTest = async (end_point, data, dataType) => {
  return await axios.post(`${apiDev}${end_point}`, data, {
    headers: {
      Authorization: token,
      "Content-Type":
        dataType === "multi" ? "multipart/form-data" : "Application/json",
    },
  });
};

const PutRequest = async (end_point, data, dataType) => {
  return await axios.put(`${api}${end_point}`, data, {
    headers: {
      Authorization: token,
      "Content-Type":
        dataType === "multi" ? "multipart/form-data" : "Application/json",
    },
  });
};

const PutRequestTest = async (end_point, data, dataType) => {
  return await axios.put(`${apiDev}${end_point}`, data, {
    headers: {
      Authorization: token,
      "Content-Type":
        dataType === "multi" ? "multipart/form-data" : "Application/json",
    },
  });
};

const getAssets = async () => {
  let response = {
    error: null,
    data: null,
    success: false,
  };
  try {
    let res = await GetRequest("/assets");
    response.data = res.data.assets;
    response.success = true;
    return response;
  } catch (error) {
    response.error =
      error?.response?.data?.msg ??
      error?.message ??
      "Unexpected Error Occured.";
    response.success = false;
    return response;
  }
};

const getEmployees = async () => {
  return await GetRequest("/employees");
};

const DeleteRequest = async (end_point) => {
  return await axios.delete(`${api}${end_point}`, {
    headers: { Authorization: token },
  });
};

const DeleteRequestTest = async (end_point) => {
  return await axios.delete(`${apiDev}${end_point}`, {
    headers: { Authorization: token },
  });
};

export {
  getAssets,
  getEmployees,
  GetRequest,
  PostRequest,
  DeleteRequest,
  PutRequest,
  PostRequestTest,
  PutRequestTest,
  DeleteRequestTest,
  CheckAuthState
};
