import React, { useState } from "react";
import DashbordContainer from "./DashboardContainer";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { PostRequest } from "../Actions/Api_Requests";
import { useDispatch } from "react-redux";
import { addEmployee } from "../../Redux/reducers/DataSlice";
import { RotatingLines } from "react-loader-spinner";
import {useNavigate} from "react-router-dom";

const AddEmployee = () => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imgError,setImgError]=useState(null);
  const defaultValues = {
    name: "",
    designation: "",
    location: "",
    email: "",
    phone: "",
  };

  const fileOnchange = (e) => {
    setImage(e.target.files[0]);
    setImgError(null);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues });

  const onSubmit = async (e) => {
    setLoading(true);
    try {

      if (!image) {
        setLoading(false);
        setImgError("Image is required");
        return;
      }
      let formData = new FormData();
      formData.append("name", e.name);
      formData.append("location", e.location);
      formData.append("email", e.email);
      formData.append("phone", e.phone);
      formData.append("designation", e.designation);
      formData.append("file", image);

      let res = await PostRequest("/api/employe/create", formData, "multi");

      dispatch(addEmployee(res.data.employe));
      reset();
      setImage(null);
      setLoading(false);
      navigate("/dashboard/employees")
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  return (
    <DashbordContainer title={"Employee Details"}>
      <div className="mt-4">
        <div className="d-flex flex-row flex-wrap align-items-center gap-4">
          <label
            htmlFor="employee-image"
            style={{
              width: "160px",
              overflow: "hidden",
              borderRadius: "5px",
              height: "120px",
              background: image ? "white" : "gray",
            }}
          >
            <input
              onChange={fileOnchange}
              style={{ display: "none" }}
              type="file"
              name="file"
              required
              id="employee-image"
            />
            {image && (
              <img
                alt=""
                src={URL.createObjectURL(image)}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            )}
          </label>
          <div className="d-flex flex-row align-items-center gap-3">
            <label
              className="dash-button dash-button-rounded px-3 bg-steelblue"
              htmlFor="employee-image"
            >
              Upload Image
            </label>
          </div>
        </div>

        {!image && imgError ?  (
          <span style={{ fontSize: "12px" }} className="text-danger">
            image is required.
          </span>
        ) : ""}

        <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
          <div className="edit-employee-grid">
            <div className="">
              <TextField
              focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                label="Name"
                variant="outlined"
                required
                error={errors?.name?.message}
                {...register("name", { required: "Name is required." })}
              />
              <TextField
              focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                label="Location"
                variant="outlined"
                required
                error={errors?.location?.message}
                {...register("location", { required: "Location is required." })}
              />
              <TextField
              focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                label="Telephone Number"
                variant="outlined"
                required
                error={errors?.phone?.message}
                {...register("phone", { required: "phone is required." })}
              />
              <TextField
              focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                label="Email Address"
                variant="outlined"
                type="email"
                inputMode="email"
                error={errors?.email?.message}
                required
                {...register("email", { required: "Email is required." })}
              />
            </div>
            <div className="">
              <TextField
              focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                label="Designation"
                variant="outlined"
                error={errors?.designation?.message}
                required
                {...register("designation", {
                  required: "Designation is required.",
                })}
              />
            </div>
          </div>

          <button
            disabled={loading}
            className="align-self-start mt-4 px-4 dash-button dash-button-rounded bg-steelblue"
          >
            {loading ? (
              <RotatingLines
                visible={true}
                height="20"
                width="20"
                color="white"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    </DashbordContainer>
  );
};

export default AddEmployee;
