import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { CheckAuthState } from "../Actions/Api_Requests";
import Cookies from "js-cookie";
import LoadingScreen from "./LoadingScreen";

const RouteShell = ({ Screen }) => {
  let { setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const AuthenticateUser = async (token) => {
    try {
      let res = await CheckAuthState("/auth/refresh", token);
      setUser(res.data.admin);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate("/login");
    }
  };

  useEffect(() => {
    const token = Cookies.get("Token");

    if (token) {
      AuthenticateUser(token);
    } else {
      navigate("/login");
    }
  }, []);

  return <>{loading ? <LoadingScreen/> : <Screen />}</>;
};

export default RouteShell;
