import React from "react";
import logo from "../HomeImages/new-logo-1.gif";
import "./css/Footer.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import IconFb from "../FooterImages/icons/fb.png";
import IconIg from "../FooterImages/icons/ig.png";
import IconLi from "../FooterImages/icons/li.png";
import IconYt from "../FooterImages/icons/yt.png";

import AddressIcon from "../FooterImages/icons/address.svg";
import EmailIcon from "../FooterImages/icons/email.svg";
import PhoneIcon from "../FooterImages/icons/phone.svg";

import Cert1 from "../FooterImages/card-img-1.png";
import Cert2 from "../FooterImages/card-img-2.png";
import Cert3 from "../FooterImages/card-img-3.png";
import Cert4 from "../FooterImages/card-img-4.png";

let page_links = [
  {
    title: "ABOUT US",
    path: "/About",
  },
  {
    title: "BRANDS",
    path: "/Brands",
  },
  {
    title: "EXPERTISE & OFFERINGS",
    path: "/Expertise",
  },
  {
    title: "CLIENTS & PROJECTS",
    path: "/Projects",
  },
  {
    title: "CONTACT US",
    path: "/Contact",
  },
];

let exp_links_1 = [
  {
    title: "Turnkey Project",
    path: "/trunky-solutions",
  },
  {
    title: "Operation & Maintenance.",
    path: "/operation-maintenance",
  },
  {
    title: "Energy Optimization.",
    path: "/annual-maintenance",
  },
  {
    title: "Troubleshooting, Programming and Configuration.",
    path: "/troubleshooting-&-config",
  },
];

let exp_links_2 = [
  {
    title: "Comfort Cooling.",
    path: "/comfort-cooling",
  },
  {
    title: "Process Cooling.",
    path: "/process-cooling-solutions",
  },
  // {
  //   title: "Cold Chain Solution.",
  //   path: "/cold-chain-solution",
  // },
  {
    title: "Import New & Used Chillers.",
    path: "/new-used-chillers",
  },
  // {
  //   title: "Consultancy.",
  //   path: "/consultancy",
  // },
  {
    title: "Spare Parts (OEM).",
    path: "/spare-parts-procurement",
  },
];

let pro_links_2 = [
  {
    title: "All Projects",
    path: "/Projects",
  },
  {
    title: "Nation Wide Projects",
    path: "/Projects/NationWide",
  },
  {
    title: "Overseas Projects",
    path: "/Projects/OverseasePorject",
  },
  {
    title: "Clients",
    path: "/Projects/ClientProject",
  },
];

let social_icons = [
  {
    img: IconFb,
    link: "https://www.facebook.com/BrothersAirConditioninghvacr/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0",
  },
  {
    img: IconIg,
    link: "https://www.instagram.com/brothersairconditioninghvacr/",
  },
  {
    img: IconLi,
    link: "https://www.linkedin.com/company/71320237/admin/",
  },
  {
    img: IconYt,
    link: "https://www.youtube.com/channel/UCnvoa_NQqsWOngYZ4naOcAQ",
  },
];

let contact_details = [
  {
    icon: AddressIcon,
    text: "Suite # 404, 4th Floor, Tahir Plaza, Plot No. A-20, K.C.H.S.U. Block 7/8, Karachi, Pakistan",
  },
  {
    icon: EmailIcon,
    text: "info@bacr.com.pk",
  },
  {
    icon: PhoneIcon,
    text: "+92-21-34322501/502",
  },
];

let certificates = [Cert1, Cert2, Cert3, Cert4];

function Footer() {
  const pathname = useLocation().pathname;
  return (
    <section className="footer-sec">
     

      <div className="container">
        <div className="_footer py-2">
          <div className="_logo_section">
            <div className="_logo_container">
              <img src={logo} className="" alt="" />
            </div>
            <p className="text-white" style={{ fontSize: "15.5px" }}>
              Trust our expert team to meet your commercial and industrial
              needs, ensuring optimal comfort and efficiency.
            </p>
          </div>
          <div className="">
            <div className="d-flex flex-row align-items-center gap-4 pb-4 flex-wrap">
              {page_links?.map((item, index) => (
                <NavLink
                  className={`${
                    pathname === item.path ? "active" : ""
                  } rounded`}
                  activeClassName="active"
                  key={index}
                  to={item.path}
                >
                  {item.title}
                </NavLink>
              ))}
              <NavLink to={"/Bac_Store"} className={"_bac_store_btn rounded"}>
                BAC STORE
              </NavLink>
            </div>

            <div className="_footer_certificates_sec">
              <div className="">
                <h5 className="text-white text-start">
                  Our Most Important and Valuable Certificates That Empowers Our
                  Work
                </h5>

                <div className="d-flex flex-row align-items-center gap-3 _certificates_parent">
                  {certificates?.map((item, index) => {
                    return (
                      <div className="rounded" key={index}>
                        <img alt="" src={item} />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="">
                <div className="_footer_details">
                  {contact_details?.map((item, index) => (
                    <div key={index} className="_detail_item_footer">
                      <div className="">
                        <img alt="" src={item.icon} />
                      </div>
                      <div className="">
                        <span>{item.text}</span>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="_footer_social_icons">
                  {social_icons?.map((item, index) => {
                    return (
                      <Link key={index} target="_blank" to={item?.link}>
                        <img alt="" src={item?.img} />
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
