import React from "react";
import ExpertiesStyledCard from "./ExpertiesStyledCard";

const WhyChooseUs4Grid = ({ title, cards }) => {
  return (
    <div className="container my-lg-5 py-5">
      <div className="my-5 py-3 text-center">
        <h2>{title ? title : "Why Choose BAC?:"}</h2>
      </div>

      <div className="_chillers_section_4_grid" style={{ gap: "5rem" }}>
        {cards?.map((item, index) => (
          <React.Fragment key={index}>
            <ExpertiesStyledCard
              title={item?.title}
              paragraph={item?.paragraph}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default WhyChooseUs4Grid;
