import React, { useState } from "react";
import DashboardContainer from "./DashboardContainer";
import { Switch, TextField } from "@mui/material";
import { db, storage } from "../../config/firebaseconfig";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useForm } from "react-hook-form";
import { RotatingLines } from "react-loader-spinner";
import { v1 as uuid } from "uuid";

const label = { inputProps: { "aria-label": "Switch demo" } };

const EditProduct = () => {
  const [loading, setLoading] = useState(false);
  const [inStock, setInStock] = useState(false);
  const [onOrder, setOnOrder] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [images, setImages] = useState([]);
  const [priorSale, setPriorSale] = useState(false);

  // Subject to prior sale
  const defaultValues = {
    productName: "",
    brandName: "",
    model: "",
    price: "",
    part_no: "",
  };

  const { handleSubmit, register, reset } = useForm({ defaultValues });

  const uploadFile = async (file) => {
    if (!file) return;

    let fileName = uuid();

    const storageRef = ref(storage, fileName);

    await uploadBytesResumable(storageRef, file);

    const filePath = `/${fileName}`;

    const fileRef = ref(storage, filePath);

    return await getDownloadURL(fileRef);
  };

  const handleOnSubmit = async (e) => {
    setLoading(true);

    try {
      if (!images.length) {
        throw new Error("Product images are required.");
      }

      if (inStock && !quantity) {
        throw new Error("Please add product quantity.");
      }

      const uploadPromises = images.map((item) => uploadFile(item));

      const productImages = await Promise.all(uploadPromises);
      const date = new Date();

      await addDoc(collection(db, "storeData"), {
        ...e,
        inStock,
        onOrder,
        quantity,
        images: productImages,
        timestamp: serverTimestamp(),
        lastModified: date,
        priorSale
      });

      reset();
      setLoading(false);
      setImages([]);
      window.history.go(-1);
    } catch (error) {
      setLoading(false);
      alert(error?.message);
    }
  };

  return (
    <DashboardContainer title={"BAC Store Details"}>
      <div className="mt-5">
        <form className="w-100" onSubmit={handleSubmit(handleOnSubmit)}>
          <div className="bac-add-product-grid">
            <div className="">
              <TextField
                focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                label="Product Name"
                required
                variant="outlined"
                defaultValue={defaultValues.productName}
                {...register("productName", { required: true })}
              />
              <TextField
                focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                required
                label="Brand Name"
                variant="outlined"
                defaultValue={defaultValues.brandName}
                {...register("brandName", { required: true })}
              />
              <TextField
                focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                required
                label="Part Number"
                variant="outlined"
                defaultValue={defaultValues.serial_no}
                {...register("part_no", { required: true })}
              />

              <div className="">
                <label
                  htmlFor="instock"
                  className="d-flex flex-row gap-2 align-items-center"
                >
                  <span>In Stock</span>
                  <Switch
                    id="instock"
                    title="In Stock"
                    checked={inStock}
                    onChange={() => {
                      setInStock(!inStock);
                    }}
                    {...label}
                  />
                </label>

                {inStock ? (
                  <>
                    <TextField
                      focused
                      id="outlined-basic"
                      fullWidth
                      type="text"
                      multiline
                      rows={1}
                      label="Quantity"
                      required={inStock}
                      variant="outlined"
                      value={quantity}
                      onChange={(e) => {
                        setQuantity(e.target.value);
                      }}
                    />

                    <label
                      htmlFor="priorSale"
                      className="d-flex flex-row gap-2 align-items-center"
                    >
                      <span>Subject to prior sale</span>
                      <Switch
                        id="priorSale"
                        title="Subject to prior sale"
                        checked={priorSale}
                        onChange={() => {
                          setPriorSale(!priorSale);
                        }}
                        {...label}
                      />
                    </label>
                  </>
                ) : (
                  ""
                )}
              </div>

              <label
                htmlFor="onorder"
                className="d-flex flex-row gap-2 align-items-center"
              >
                <span>On Order Only</span>
                <Switch
                  id="onorder"
                  title="On Order Only"
                  checked={onOrder}
                  onChange={() => {
                    setOnOrder(!onOrder);
                  }}
                  {...label}
                />
              </label>
            </div>
            <div className="">
              <TextField
                focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                label="Model"
                defaultValue={defaultValues.model}
                variant="outlined"
                {...register("model", { required: true })}
              />
              <TextField
                focused
                multiline
                rows={1}
                id="outlined-basic"
                fullWidth
                required
                label="Price"
                defaultValue={defaultValues.price}
                variant="outlined"
                {...register("price", { required: true })}
              />
            </div>
          </div>

          <div className="d-flex flex-column mt-4">
            <h5>Product Images</h5>
            <div className="d-flex flex-wrap flex-row align-items-center gap-4 mt-2">
              {images?.length
                ? images?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="d-flex flex-row align-items-center justify-content-center"
                        style={{
                          background: "#ccc",
                          height: "150px",
                          width: "150px",
                          borderRadius: "8px",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-x"
                          viewBox="0 0 16 16"
                          onClick={() => {
                            let newArry = images?.filter(
                              (_, id) => id !== index
                            );
                            setImages(newArry);
                          }}
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            zIndex: 1,
                            cursor: "pointer",
                            background: "white",
                            borderRadius: "50px",
                          }}
                        >
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                        <img
                          alt=""
                          style={{
                            width: "100%",
                            objectFit: "contain",
                            height: "100%",
                          }}
                          src={URL.createObjectURL(item)}
                        />
                      </div>
                    );
                  })
                : ""}

              <label
                htmlFor="product-image"
                className="d-flex flex-row align-items-center justify-content-center"
                style={{
                  background: "#ccc",
                  height: "150px",
                  width: "150px",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                <AddIcon />
                <input
                  style={{
                    display: "none",
                  }}
                  id="product-image"
                  type="file"
                  multiple
                  required
                  onChange={(e) => {
                    setImages((pre) => [...pre, ...e.target.files]);
                  }}
                />
              </label>
            </div>
          </div>

          <button
            type="submit"
            disabled={loading}
            className="mt-4 align-self-start px-4 dash-button dash-button-rounded bg-steelblue"
          >
            {loading ? (
              <RotatingLines
                visible={true}
                height="20"
                width="20"
                color="white"
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperClass=""
              />
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    </DashboardContainer>
  );
};

export default EditProduct;

const AddIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="currentColor"
      className="bi bi-plus-square"
      viewBox="0 0 16 16"
    >
      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
    </svg>
  );
};
