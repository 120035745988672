import React, { useEffect } from "react";
import "../../components/OperationInner/OperateInner.css";
import Section3 from "./Section3";
import NavigateBack from "../ExpertiseInner/NavigateBack";
import Footer from "../AboutSec/Footer";
import Section4 from "./Section4";
import PageHeroSection from "../Exp_inner_Components/PageHeroSection";
import PageDescription from "../Exp_inner_Components/PageDescription";

function TroubleshootingAndConfig() {
  useEffect(() => {
    document.body.classList.add("menu-is-closed");
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavigateBack />
      <PageHeroSection
        parentStyles={"programming-confrigration"}
        title={"Troubleshooting, Programming and Confrigration"}
        // description={`We provide professional Industrial process cooling solutions
        //           as per specific requirement of the process machinery.`}
      />
      <PageDescription
        title={"Welcome to BAC - Your HVACR Chiller Experts"}
        description={`At BAC, we pride ourselves on being the go-to experts for
      troubleshooting, programming, and configuration of HVACR chillers
      and equipment. With years of experience and a team of highly
      skilled technicians, we offer comprehensive services to ensure
      your HVACR systems operate at peak performance.`}
      />
      <Section3 />
      <Section4 />

      <Footer />
    </>
  );
}

export default TroubleshootingAndConfig;
