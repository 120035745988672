import React from "react";
import CardImg1 from "./images/card-img-1.png";
import CardImg2 from "./images/card-img-2.png";
import CardImg3 from "./images/card-img-3.png";
import CardImg4 from "./images/card-img-4.png";
import CardImg5 from "./images/card-img-5.png";
import ImgTextGrid from "../Exp_inner_Components/ImgTextGrid";

let cards = [
  {
    img: CardImg1,
    imgLeft: true,
    title: "Precision Cooling Technology",
    paragraph: `Our HVACR Chillers are engineered to deliver precise and consistent cooling performance, maintaining the desired temperature range for your products with utmost accuracy. From pharmaceuticals to fresh produce, we understand the critical importance of temperature control in preserving product integrity.`,
  },
  {
    img: CardImg2,
    imgLeft: false,
    title: "Energy Efficiency",
    paragraph: `We prioritize energy efficiency in all our solutions, helping you reduce operational costs and minimize environmental impact. Our state-of-the-art chillers are designed to optimize energy consumption without compromising on cooling efficiency, providing sustainable Cold Chain Storage solutions for your business.`,
  },
  {
    img: CardImg3,
    imgLeft: true,
    title: "Customized Solutions",
    paragraph: `Every industry has unique Cold Chain requirements, and we recognize the need for tailored solutions. Whether you operate in food and beverage, healthcare, or any other sector, our team works closely with you to design and implement Cold Chain Storage systems that meet your specific needs and regulatory standards.`,
  },
  {
    img: CardImg4,
    imgLeft: false,
    title: "Reliability and Durability ",
    paragraph: `BAC equipment is renowned for its reliability and durability, ensuring uninterrupted operation even in the most demanding environments. With robust construction and quality components, our chillers and HVACR equipment deliver exceptional performance day in and day out, safeguarding your valuable inventory.`,
  },
  {
    img: CardImg5,
    imgLeft: true,
    title: "Comprehensive Support ",
    paragraph: `From initial consultation to ongoing maintenance, our dedicated team provides comprehensive support at every stage of your Cold Chain journey. We offer expert advice, installation services, preventive maintenance programs, and responsive technical assistance to keep your Cold Chain Storage system running smoothly.`,
  },
];

const Section2 = () => {
  return (
    <div className="container my-5">
      <div className="my-5 py-5 text-center">
        <h2>Our Expertise</h2>
      </div>

      <div className="d-flex flex-column" style={{ gap: "5rem" }}>
        {cards?.map((item, index) => {
          return <ImgTextGrid key={index} item={item} />;
        })}
      </div>
    </div>
  );
};

export default Section2;
