import "./styles/App.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import { useEffect, useState } from "react";
import LoadScreen from "./components/LoadScreen";

function App() {
  const [timerId, setTimerId] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTimerId(true);
    }, 3500);
  }, []);

  return (
    <section style={{ position: "relative" }}>
      <LoadScreen dis_none={timerId} />
      <Navbar navStyles={" _hero_nav_style"} isHome={"r"} />
      <Home />
    </section>
  );
}
export default App;
