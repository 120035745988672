import React, { useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../AboutSec/Footer";
import SectionOne from "./components/SectionOne";
import SectionTwo from "./components/SectionTwo";

const Brands = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <SectionOne/>
      <SectionTwo/>
      <Footer />
    </>
  );
};

export default Brands;
