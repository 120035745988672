import React, { useEffect } from "react";
import "./OperateInner.css";
import Operate3 from "./Operate3";
import NavigateBack from "../ExpertiseInner/NavigateBack";
import Footer from "../AboutSec/Footer";
import WhyChooseUs from "../Exp_inner_Components/WhyChooseUs";
import PageHeroSection from "../Exp_inner_Components/PageHeroSection";
import PageDescription from "../Exp_inner_Components/PageDescription";

let cards = [
  {
    title: "Customized Solutions",
    paragraph:
      "We understand that every client has unique requirements and challenges. That's why we offer personalized solutions tailored to your specific needs, budget, and performance goals.",
  },
  {
    title: "Commitment to Quality",
    paragraph:
      "Quality is at the core of everything we do. From our meticulous maintenance procedures to our responsive customer service, we prioritize excellence in every aspect of our work.",
  },
  {
    title: "Customer Satisfaction",
    paragraph:
      "We understand that every client has unique requirements and challenges. That's why we offer personalized solutions tailored to your specific needs, budget, and performance goals.",
  },
  {
    title: "Cost-Effective Solutions",
    paragraph:
      "We believe in delivering value to our clients. By optimizing system performance, minimizing downtime, and extending equipment life, we help you save money in the long run.",
  },
];

function OperationInr() {
  useEffect(() => {
    document.body.classList.add("menu-is-closed");
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavigateBack />

      <PageHeroSection
        parentStyles={"operate-bg-img "}
        title={"Operation & Maintenance"}
        description={`Operation and Maintenance Excellence`}
      />
      <PageDescription
        title={`Welcome to BAC - Your HVACR Chiller Operation and Maintenance Experts`}
        description={`At BAC, we specialize in providing top-notch operation and maintenance services for HVACR chillers and associated equipment. With years of experience and a team of highly skilled technicians, we ensure the optimal performance and longevity of your HVACR systems, keeping your indoor environment comfortable and efficient.`}
      />
      <Operate3 />
      <WhyChooseUs
        title2={"Expertise and Experience"}
        paragraph={`
      Experience robust support with our steam beam base, meticulously designed to enhance the stability and efficiency of HVACR systems. Our solution ensures reliable performance and durability, providing a strong foundation for your equipment needs.
      `}
        cards={cards}
      />
      <Footer />
    </>
  );
}

export default OperationInr;
