import React, { useEffect, useState } from "react";
import Footer from "../AboutSec/Footer";
import { NavLink, useParams } from "react-router-dom";
import { GetRequest } from "../../Admin/Actions/Api_Requests";
import "./brandetails.css";
import { RotatingLines } from "react-loader-spinner";
import NavigateBack from "../ExpertiseInner/NavigateBack";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 850,
  maxHeight: "80vh",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "8px",
  border: "none",
  outline: "none",
};

const BrandDetails = () => {
  const [cardDetails, setCardDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    document.body.classList.add("menu-is-closed");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  let fetchItems = async () => {
    setLoading(true);
    try {
      let res = await GetRequest(`/api/card/${id}`);
      setCardDetails(res.data.card);
      setLoading(false);
    } catch (error) {
      window?.history?.go(-1);
    }
  };

  useEffect(() => {
    if (id && !cardDetails) {
      fetchItems();
    }
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{ height: "100vh" }}
          className="w-100 d-flex flex-row align-items-center justify-content-center"
        >
          <RotatingLines
            visible={true}
            height="96"
            width="96"
            color="grey"
            strokeColor="blue"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{
              color: "blue",
            }}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="">
          <NavigateBack />

          {cardDetails ? (
            <>
              <div className="details-main-sec d-flex flex-column align-items-center justify-content-center gap-3 text-center">
                <div className="brand_bg_container">
                  <img alt="" src={cardDetails?.bg_img?.path} />
                </div>
                <img alt="" src={cardDetails?.image?.path} />

                <NavLink
                  className={
                    "rounded text-black bg-white p-2 text-decoration-none"
                  }
                  to={cardDetails?.website_url}
                  target="_blank"
                  style={{
                    zIndex: "100",
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    fontSize:"14px"
                  }}
                >
                  Visit Website
                </NavLink>
              </div>

              <div className="container mx-auto _details_py_24">
                <p className="px-lg-5 text-center">
                  {cardDetails?.description}
                </p>
              </div>

              {cardDetails?.items?.length ? (
                <div className="details-cards">
                  <div className="container">
                    <div className="details-cards-grid">
                      {cardDetails?.items?.length
                        ? cardDetails?.items?.map((item, index) => (
                            <div className="" key={index}>
                              <h2 className="text-center mb-3">
                                {item?.title}
                              </h2>
                              <div className="card-item rounded">
                                <div className="_deatils_img_container">
                                  <img
                                    alt=""
                                    className=""
                                    src={item?.image?.path}
                                  />
                                </div>
                                <div className="my-4 px-4 card_list_container">
                                  {item?.features?.map((feature, id) => {
                                    return (
                                      <span className="" key={id}>
                                        {feature}
                                      </span>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <div
              style={{
                minHeight: "100vh",
              }}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <h1 style={{ fontWeight: "bolder" }}>404</h1>
              <h3>Brand Not Found</h3>
            </div>
          )}
        </div>
      )}

      <Footer />
    </>
  );
};

const DescriptionModel = ({ description }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <span
        onClick={handleOpen}
        className="text-primary text-decoration-underline"
        style={{
          cursor: "pointer",
          fontSize: "15px",
        }}
      >
        read more
      </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ul>
            {[0, 0, 0, 0].map((item, index) => {
              return <li key={index}>Fan coil units with cabinet</li>;
            })}
          </ul>
        </Box>
      </Modal>
    </div>
  );
};

export default BrandDetails;
