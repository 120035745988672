import React from "react";
import CardImg1 from "./images/card-img-1.png";
import CardImg2 from "./images/card-img-2.png";
import CardImg3 from "./images/card-img-3.png";
import ImgTextGrid from "../Exp_inner_Components/ImgTextGrid";

let cards = [
  {
    imgLeft: true,
    img: CardImg1,
    title: "Project Consultancy",
    paragraph:
      "Project Consultancy: Whether you're embarking on a new construction project or retrofitting an existing facility, our consultancy team is here to guide you every step of the way. We specialize in designing, planning, and executing HVACR projects of all sizes and complexities. From initial concept development to final commissioning, we ensure that your project is delivered on time, within budget, and to the highest standards of quality.",
  },
  {
    imgLeft: false,
    img: CardImg2,
    title:"Supply Solutions",
    paragraph:
      "As a leading provider of HVACR equipment and components, we offer a comprehensive range of supply solutions to meet your needs. Whether you're looking for chillers, air handling units, controls, or refrigeration systems, we have the products and expertise to support your project. Our partnerships with top manufacturers ensure that you have access to the latest technologies and highest-quality products on the market.",
  },
  {
    imgLeft: true,
    img: CardImg3,
    title: "Service Excellence",
    paragraph:
      "Our commitment to excellence doesn't end with project completion. We offer a wide range of services to support the ongoing operation and maintenance of your HVACR systems. From preventive maintenance programs and emergency repairs to system upgrades and retrofits, our team of skilled technicians is here to keep your systems running smoothly and efficiently, minimizing downtime and maximizing performance",
  },
];

function ChillerPlant2() {
  return (
    <>
      <div className="container my-5">
        <div className="my-5 py-5 text-center">
          <h2>Our Expertise</h2>
        </div>

        <div className="d-flex flex-column" style={{gap:"5rem"}}>
          {cards?.map((item, index) => {
            return <ImgTextGrid key={index} item={item} />;
          })}
        </div>
      </div>
    </>
  );
}

export default ChillerPlant2;
