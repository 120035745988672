import React from "react";
import SecImg1 from "./images/section-3-img-1.png";
import SecImg2 from "./images/section-3-img-2.png";
import SecImg3 from "./images/section-3-img-3.png";

function ChillerPlant3() {
  return (
    <>
      <div className="_consultancy_section_3 container-fluid">
        <div className="container py-5">
          <div className="_consultancy_section_3_grid row align-items-center">
            <div
              data-aos="fade-right"
              className="d-flex flex-column col-lg-7 align-self-start"
            >
              <p>
                Whether you're planning a new project, sourcing HVACR equipment,
                or need ongoing support and services, BAC is here to help.
                Contact us today to learn more about how we can support your
                HVACR initiatives and help you achieve your goals. Let's work
                together to create solutions that deliver comfort, efficiency,
                and reliability for your facility.
              </p>
            </div>
            <div className="_consultancy_section_3_grid_item d-flex flex-column col-lg-5">
              <img data-aos="zoom-out-left" alt="" src={SecImg1} />
            </div>
            <div className="_consultancy_section_3_grid_item d-flex flex-column col-lg-7">
              <img data-aos="zoom-out-right" alt="" src={SecImg2} />
            </div>
            <div className="_consultancy_section_3_grid_item d-flex flex-column col-lg-5">
              <img data-aos="zoom-out-left" alt="" src={SecImg3} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChillerPlant3;
