import React from "react";
import "./troubleshooting.css";
import CardImg1 from "./images/card-img-1.webp";
import CardImg2 from "./images/card-img-2.webp";
import CardImg3 from "./images/card-img-3.webp";
import CardImg4 from "./images/card-img-4.webp";
import CardImg5 from "./images/card-img-5.webp";
import ImgTextGrid from "../Exp_inner_Components/ImgTextGrid";

let cards = [
  {
    img: CardImg1,
    imgLeft: true,
    title: "Rapid Issue Resolution",
    paragraph:
      "Count on our team for swift and effective troubleshooting services. We leverage our expertise to identify and resolve issues quickly, minimizing downtime and disruptions to your operations.",
  },
  {
    imgLeft: false,
    img: CardImg2,
    title: "Programming Services",
    paragraph:
      "Unlock the full potential of your systems with our programming solutions. We specialize in programming chillers and optimizing control algorithms to meet your specific requirements.",
  },
  {
    imgLeft: true,
    img: CardImg3,
    title: "Configuration Optimization",
    paragraph:
      "Ensure optimal performance with our configuration optimization services. We fine-tune your systems to maximize efficiency and functionality, allowing you to achieve peak performance and reliability.",
  },

  {
    imgLeft: false,
    img: CardImg4,
    title: "Skilled Technicians",
    paragraph:
      "Our team of experienced technicians is equipped with the knowledge and skills to handle complex troubleshooting, programming, and configuration tasks. You can trust us to deliver results that exceed your expectations.",
  },

  {
    imgLeft: true,
    img: CardImg5,
    title: "Dedicated Support",
    paragraph:
      "From troubleshooting to programming and configuration, we provide dedicated support throughout the lifecycle of your systems. We're here to help you overcome challenges and optimize performance every step of the way.",
  },
];

function Chiller3() {
  return (
    <>
      <div className="container my-5">
        <div className="_operation_section_3">
          {cards?.map((item, index) => {
            return <ImgTextGrid key={index} item={item} />;
          })}
        </div>
      </div>
    </>
  );
}

export default Chiller3;
