import React, { useEffect } from "react";
import "./spare_parts_procurement.css";
import NavigateBack from "../ExpertiseInner/NavigateBack";
import PageDescription from "../Exp_inner_Components/PageDescription";
import Footer from "../AboutSec/Footer";
import Section2 from "./Section2";
import PageHeroSection from "../Exp_inner_Components/PageHeroSection";

const SparePartsProcurement = () => {
  useEffect(() => {
    document.body.classList.add("menu-is-closed");
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavigateBack />

      <PageHeroSection
        parentStyles={" spare_parts_procurement "}
        title={`Spare Parts Procurement`}
        description={`We provide professional Industrial process Spare Parts Procurement as per specific requirement of the process machinery.`}
      />

      <PageDescription
        headingStyles={" col-lg-9"}
        title={"Welcome to BAC Spare Parts Procurement: "}
        description={`Your Trusted Partner in HVACR Excellence At BAC, we understand the critical role that spare parts and consumables play in maintaining the optimal performance of your chillers. With years of expertise in the HVACR industry, we have honed our skills to become your go-to source for sourcing top-quality spare parts and consumables.`}
      />

      <Section2 />
      <Footer />
    </>
  );
};

export default SparePartsProcurement;
