import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { PutRequestTest } from "../Actions/Api_Requests";
import { useDispatch } from "react-redux";
import { updateChildCard } from "../../Redux/reducers/DataSlice";
import { RotatingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { plusIcon } from "../New Components/routes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
};

const EditChildForm = ({ parentId, childId }) => {
  const { cards } = useSelector((item) => item.DataSlice);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = React.useState(false);
  const [cardImage, setCardImage] = React.useState(null);
  const [title, setTitle] = React.useState("");
  const [currentImg, setCurrentImage] = React.useState(null);
  const [errors, setErrors] = React.useState({
    title: null,
    description: null,
  });
  const [newFeature, setNewFeature] = React.useState("");
  const [features, setFeatures] = React.useState([]);
  const [fieldErrors, setFieldError] = React.useState({
    logo: null,
    features: null,
  });

  const dispatch = useDispatch();

  const ImageOnChange = (e) => {
    setCardImage(e.target.files[0]);
    if (fieldErrors?.logo) {
      setFieldError((pre) => ({ ...pre, logo: null }));
    }
  };

  const handleAddNewFeature = () => {
    if (newFeature?.trim()?.length > 0) {
      if(features?.length){
        setFeatures((e) => [...e, newFeature]);
      }else{
        setFeatures([newFeature]);
      }
      setNewFeature("");
    }
  };

  const handleRemoveFeature = (feature) => {
    let newArr = features?.filter(
      (item) => item?.toLowerCase() !== feature?.toLowerCase()
    );
    setFeatures(newArr);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!currentImg && !cardImage) {
        setFieldError((pre) => ({
          ...pre,
          logo: "Brand Logo Image is required.",
        }));
        setLoading(false);
        return;
      }

      if (!features?.length) {
        setFieldError((pre) => ({
          ...pre,
          features: "Please add atleast one point.",
        }));
        setLoading(false);
        return;
      }

      if (!title.trim().length) {
        setErrors({ ...errors, title: "Please add a title." });
        setLoading(false);
        return;
      }

      let payload = {
        title,
        features,
        logo: cardImage,
      };
      let res = await PutRequestTest(
        `/api/card/update/child/${childId}`,
        payload,
        "multi"
      );
      dispatch(
        updateChildCard({
          child: childId,
          parent: parentId,
          card: res.data.card,
        })
      );

      setLoading(false);
      setCardImage(null);
      setCurrentImage(null);
      setFeatures([]);
      setNewFeature("");
      setTitle("");
      setFieldError({
        logo: null,
        bg_img: null,
        features: null,
      });
      setOpen(!open);
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (parentId && childId) {
      let card = cards?.find((item) => item._id === parentId);
      if (card) {
        let childCard = card?.items?.find((item) => item._id === childId);
        setTitle(childCard?.title);
        setCurrentImage(childCard?.image?.path);
        setFeatures(childCard?.features);
      } else {
        alert("Unable to find Card.");
        setOpen(false);
      }
    } else {
      alert("Unable to find Card.");
      setOpen(false);
    }
  }, [cards, parentId, childId, open]);

  return (
    <div>
      <button className="btn" onClick={handleOpen}>
        <EditSvg />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form
            onSubmit={onSubmit}
            className="p-4"
            style={{
              height: "55vh",
              overflow: "auto",
            }}
          >
            <div className="d-flex flex-col mb-3">
              <label
                htmlFor="card-1-img"
                style={{
                  width: "100px",
                  overflow: "hidden",
                  borderRadius: "5px",
                  height: "70px",
                  cursor: "pointer",
                  background: cardImage ? "white" : "#ccc",
                }}
              >
                <input
                  onChange={ImageOnChange}
                  style={{ display: "none" }}
                  type="file"
                  name="card-1-img"
                  id="card-1-img"
                />
                {cardImage ? (
                  <img
                    alt=""
                    src={URL.createObjectURL(cardImage)}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                ) : currentImg ? (
                  <img
                    alt=""
                    src={currentImg}
                    style={{
                      objectFit: "fill",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                ) : (
                  <div className="h-100 d-flex flex-column gap-2 justify-content-center align-items-center">
                    <ImageSvg />
                    <span className="text-white">Select image</span>
                  </div>
                )}
              </label>
              {fieldErrors?.logo && !currentImg && !cardImage ? (
                <span style={{ fontSize: "12px", color: "red" }}>
                  Brand Logo Image is required.
                </span>
              ) : (
                ""
              )}
            </div>
            
            <div className="gap-4 mt-4 d-flex flex-column">
              <TextField
                size={"small"}
                multiline
                rows={1}
                focused
                id="outlined-basic"
                fullWidth
                label="Title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                variant="outlined"
              />

              {errors?.title ? (
                <span style={{ fontSize: "12px", color: "red" }}>
                  {errors?.title}
                </span>
              ) : (
                ""
              )}

            </div>

            <div className="d-flex flex-column gap-2 mt-4">
              <div className="d-flex flex-row align-items-center gap-3">
                <TextField
                  size={"small"}
                  value={newFeature}
                  onChange={(e) => {
                    setNewFeature(e.target.value);
                    if (fieldErrors?.features) {
                      setFieldError((pre) => ({ ...pre, features: null }));
                    }
                  }}
                  focused
                  id="outlined-basic"
                  fullWidth
                  type="text"
                  label="Feature"
                  variant="outlined"
                />

                <button
                  disabled={newFeature?.length < 1 ? true : false}
                  onClick={handleAddNewFeature}
                  type="button"
                  className="dash-button rounded px-3 text-white d-flex  bg-steelblue align-items-center gap-1"
                >
                  {plusIcon()}
                </button>
              </div>

              {fieldErrors?.features ? (
                <span style={{ fontSize: "12px", color: "red" }}>
                  {fieldErrors?.features}
                </span>
              ) : (
                ""
              )}

              <div className="d-flex flex-column gap-2 ">
                {features?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="row align-items-center rounded mx-0 py-1"
                      style={{
                        backgroundColor: "#ccc",
                      }}
                    >
                      <div className="col-11" style={{ fontSize: "14px" }}>
                        {item?.length > 45 ? (
                          <>{item?.slice(0, 45)}... </>
                        ) : (
                          item
                        )}
                      </div>

                      <div className="col-1">
                        <svg
                          onClick={() => {
                            handleRemoveFeature(item);
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          className="bi bi-x"
                          style={{ cursor: "pointer" }}
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <button className="align-self-start mt-4 px-4 dash-button dash-button-rounded bg-steelblue">
              {loading ? (
                <RotatingLines
                  visible={true}
                  height="20"
                  width="20"
                  color="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export const ImageSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="white"
      className="bi bi-image"
      viewBox="0 0 16 16"
    >
      <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
      <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
    </svg>
  );
};

const EditSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-pencil-square"
      viewBox="0 0 16 16"
    >
      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
      <path
        fillRule="evenodd"
        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
      />
    </svg>
  );
};

export default EditChildForm;
