import React from "react";
import img1 from "./Operate_Images/operation-card-img-1.webp";
import img2 from "./Operate_Images/operation-card-img-2.webp";
import img3 from "./Operate_Images/operation-card-img-3.webp";
import img4 from "./Operate_Images/operation-card-img-4.webp";
import img5 from "./Operate_Images/operation-card-img-5.webp";
import img6 from "./Operate_Images/operation-card-img-6.webp";
import ImgTextGrid from "../Exp_inner_Components/ImgTextGrid";

let cards = [
  {
    img: img1,
    imgLeft: true,
    title: "Peak Performance Assurance",
    paragraph:
      "Ensure your systems run smoothly and efficiently with our comprehensive operation and maintenance services. We're committed to maximizing uptime and performance, allowing you to focus on your core business activities.",
  },
  {
    imgLeft: false,
    img: img2,
    title: "Proactive Maintenance",
    paragraph:
      "Our proactive approach to maintenance helps prevent costly downtime and extends the lifespan of your equipment. From routine inspections to predictive analytics, we identify and address potential issues before they escalate, keeping your systems operating at peak efficiency.",
  },
  {
    imgLeft: true,
    img: img3,
    title: "Annual Operation and Maintenance Plans",
    paragraph:
      "Opt for our annual operation and maintenance plans tailored to suit your specific needs and budget. These plans offer regular inspections, preventive maintenance, and priority service to ensure the continuous operation of your systems throughout the year.",
  },
  {
    imgLeft: false,
    img: img4,
    title: "Skilled Technicians",
    paragraph:
      "Our team of skilled technicians is trained to handle a wide range of maintenance tasks, from routine servicing to complex repairs. With years of experience and industry expertise, you can trust us to keep your systems running smoothly year-round.",
  },
  {
    imgLeft: true,
    img: img5,
    title: "Customer Satisfaction",
    paragraph:
      "At Brothers Air Conditioning, customer satisfaction is our top priority. We strive to deliver exceptional service and support, ensuring that your operation runs smoothly and efficiently at all times.",
  },
  {
    imgLeft: false,
    img: img6,
    title: "Partner with Us",
    paragraph:
      "Partner with Brothers Air Conditioning for reliable operation and maintenance services that you can count on. Experience peace of mind knowing that your systems are in good hands, allowing you to focus on what matters most—your business.",
  },
];

function Operate3() {
  return (
    <>
      <div className="container operate3-main my-5">
        <div className="my-5 py-5 text-center">
          <h2>Why Choose Us</h2>
        </div>

        <div className="_operation_section_3">
          {cards?.map((item, index) => {
            return <ImgTextGrid key={index} item={item} />;
          })}
        </div>
      </div>
    </>
  );
}

export default Operate3;
