import React from "react";
import Logo from "../../components/assets/loadingScreen.gif";

const LoadingScreen = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center bg-steelblue login-page-container ">
        <div className="">
          <img alt="" src={Logo} style={{width:"100%"}} />
        </div>
    </div>
  );
};

export default LoadingScreen;
