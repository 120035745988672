import React from "react";
import CardImg1 from "./images/card-img-1.webp";
import CardImg2 from "./images/card-img-2.webp";
import CardImg3 from "./images/card-img-3.webp";
import CardImg4 from "./images/card-img-4.webp";
import CardImg5 from "./images/card-img-5.webp";
import ImgTextGrid from "../Exp_inner_Components/ImgTextGrid";
import './newandusedchillers.css';

let cards = [
  {
    img: CardImg1,
    imgLeft: true,
    paragraph:
      "At BAC, we specialize in importing both new and used chillers of exceptional quality. With years of experience in the industry, we have honed our skills and cultivated a deep understanding of the complexities involved in importing chillers. Our team of experts ensures that each chiller we import meets the highest standards of quality and reliability, guaranteeing optimal performance and longevity for our clients.",
  },
  {
    img: CardImg2,
    imgLeft: false,
    paragraph: `
    What sets us apart is our extensive network of contacts spanning across different countries and regions. This network enables us to source the finest chillers from around the globe, ensuring a diverse range of options to suit every need and requirement. Whether you're looking for a specific brand, capacity, or customization, our vast industry connections empower us to fulfill your needs efficiently and effectively.`,
  },
  {
    img: CardImg3,
    imgLeft: true,
    paragraph: `
    In addition to our importing expertise, we are proud to be authorized dealers of leading European brands in the HVACR industry. Our dealership offers access to the latest and most innovative chillers available, tailored to meet the unique requirements of your projects. From energy-efficient solutions to cutting-edge technology, we provide a comprehensive range of options to elevate your HVACR systems to new heights.`,
  },
  {
    img: CardImg4,
    imgLeft: false,
    paragraph: `
    At BAC, we are committed to maintaining the goodwill and reputation of our company in the industry. That's why we exclusively sell our self-imported chillers, ensuring that each unit meets our rigorous quality standards. By maintaining control over the importing process and offering only the highest quality chillers, we uphold our commitment to customer satisfaction and market integrity.`,
  },
  {
    img: CardImg5,
    imgLeft: true,
    paragraph: `
    Experience the BAC difference and unlock unparalleled quality and performance for your HVACR needs. Partner with us today and discover why we are the preferred choice for top-quality chillers and HVACR solutions.`,
  },
];

function Rental3() {
  return (
    <>
      <div className="container my-5">
        <div className="d-flex flex-column" style={{
          gap:"7rem"
        }}>
          {cards?.map((item, index) => {
            return <ImgTextGrid item={item} index={index} />;
          })}
        </div>
      </div>
    </>
  );
}

export default Rental3;
