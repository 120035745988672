import React, { useEffect } from "react";
import "./Contact.css";
import Navbar from "../Navbar";
import conactimg from "./images/contactimg.jpg";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Footer from "../AboutSec/Footer";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <div className="row mb-5">
          <div className="col-xl-6 col-lg-6 col-sm-12 col-md-12 mt-5 ">
            <div className="mb-5">
              <h4 data-aos="fade-down" className="title1">Contact Us</h4>
              <h1 data-aos="fade-right" className="fw-bolder text-start">
                Shape Your Tomorrow with Exciting Opportunities at BAC
              </h1>
              <p className="mt-1 w-100" data-aos="fade-left">
                Join Our Dynamic Team Dedicated to Innovation, Collaboration,
                and Excellence. Explore Open Positions and Play a Vital Role in
                Shaping the Future of Air Conditioning Solutions.
              </p>
            </div>
          </div>

          <div className="col-xl-6 col-lg-5 col-sm-12 col-md-12  cont-R-main">
            <div className="cont-R  d-flex justify-content-center align-items-center">
              <div className="position-relative">
                <img src={conactimg} alt="" />
                {/* <div className="bg-div position-absolute "></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Section2 />
        <Section3 />
        <Section4 />
      </div>
      <Footer />
    </>
  );
}

export default Contact;
