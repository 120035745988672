import React, { useEffect, useState } from "react";
import proj1 from "../images/ahad.webp";
import proj2 from "../images/team.webp";
const Header = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount < 500) {
          return prevCount + 1;
        } else {
          clearInterval(interval);
          return prevCount;
        }
      });
    }, 10);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container project-man my-5">
      <div className="row ">
        <div className="col-lg-6 col-sm-12 col-md-12 mt-5">
          <div className="proj-L">
            <h4 data-aos="fade-right">Our Projects</h4>
            <h1 data-aos="fade-left " className="text-start">
              Our Team's Dedication and Successful Projects
            </h1>
            <p className="" data-aos="fade-down">
              Discover the results of our team's dedication and expertise
              through our successful HVAC projects, showcasing our commitment to
              excellence and innovation
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12 col-md-12 mt-5 projR-main ">
          <div className="proj-R ">
            <div className="proj1-img-div ">
              <img src={proj1} data-aos="fade-right" alt="" />
              <div
                className="prog1-text d-flex justify-content-center align-items-center"
                data-aos="fade-down"
              >
                <div className="mt-3">
                  <p className="text-center ">
                    We execute projects on a national and international scale,
                    catering to a diverse clientele.
                  </p>
                </div>
              </div>
            </div>
            <div className="proj2-main  w-50">
              <div className="proj2-img-div">
                <img src={proj2} data-aos="zoom-in" alt="" />

                <div className="prog2-text d-flex justify-content-center  ">
                  <div className="d-flex justify-content-center align-items-center flex-column ">
                    <span className="fs-4 fw-bolder ">{count}+</span>
                    <span>Projects</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
