import React, { useEffect, useState } from "react";
import DashboardContainer from "./DashboardContainer";
import { useSelector, useDispatch } from "react-redux";
import {
  DeleteRequest,
  GetRequest,
  PostRequestTest,
  PutRequestTest,
} from "../Actions/Api_Requests";
import {
  addDoc,
  setDocs,
  deleteDoc,
  updateDoc,
} from "../../Redux/reducers/DataSlice";
import { RotatingLines } from "react-loader-spinner";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { plusIcon } from "./routes";
import { TextField } from "@mui/material";
import swal from "sweetalert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "8px",
};

const Certificates = () => {
  let dispatch = useDispatch();
  let { certificates } = useSelector((state) => state.DataSlice);
  let [isDeleting, setIsDeleting] = useState(false);
  let [delItemId, setDelItemId] = useState();

  let fetchDocs = async () => {
    try {
      let res = await GetRequest("/api/docs");
      dispatch(setDocs(res?.data?.docs));
    } catch (error) {
      swal("Opps...", "Something went wrong, while retriving data.")
    }
  };

  useEffect(() => {
    if (!certificates?.length) {
      fetchDocs();
    }
  }, []);

  let deleteDochandler = async (id) => {
    setDelItemId(id);
    setIsDeleting(true);
    try {
      await DeleteRequest(`/api/docs/delete/${id}`);
      dispatch(deleteDoc(id));
      setIsDeleting(false);
    } catch (error) {
      setIsDeleting(false);
      alert(error.message);
    }
  };

  return (
    <DashboardContainer>
      <div className="mb-4 d-flex flex-row align-items-center justify-content-between">
        <h4 className="text-start">Certificates</h4>
        <NewDocModel />
      </div>
      <div className="employees-container my-2">
        {certificates?.map((item, index) => (
          <div
            className="shadow certificate-card py-3 rounded d-flex flex-column gap-3"
            key={index}
          >
            <div className="certificate-img-container rounded">
              <img src={item?.path} alt="..." />
            </div>
            <h5 class="text-center font-weight-bold">{item?.name}</h5>
            <div className=" d-flex flex-row align-items-center justify-content-center gap-2">
              <UpdateDocModel id={item._id} />
              <button
                onClick={() => {
                  deleteDochandler(item._id);
                }}
                disabled={isDeleting}
                className="image-delete-btn px-3 text-white"
              >
                {isDeleting && item._id === delItemId ? (
                  <RotatingLines
                    visible={true}
                    height="20"
                    width="20"
                    color="white"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperClass=""
                  />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        ))}
      </div>
    </DashboardContainer>
  );
};

function NewDocModel() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [cardImage, setCardImage] = useState(null);
  const [name, setName] = useState("");

  const ImageOnChange = (e) => {
    setCardImage(e.target.files[0]);
  };
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("file", cardImage);
      formData.append("name", name);
      let res = await PostRequestTest("/api/docs/upload", formData, "multi");
      dispatch(addDoc(res.data.doc));
      setName("");
      setCardImage(null);
      setLoading(false);
      setOpen(false);
    } catch (error) {
      setLoading(false);
      alert(error?.message);
    }
  };

  return (
    <div>
      <button
        onClick={handleOpen}
        className="dash-button rounded px-3 text-white d-flex  bg-steelblue align-items-center gap-1"
      >
        {plusIcon()}
        <span>Add Certificate</span>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={onSubmit} className="d-flex flex-column gap-4">
            <input
              onChange={ImageOnChange}
              style={{ display: "none" }}
              type="file"
              name="card-1-img"
              required={!cardImage ? true : false}
              id="card-1-img"
            />
            <label
              htmlFor="card-1-img"
              className="d-flex w-100 rounded align-items-center justify-content-center flex-column"
              style={{ background: "#ccc", height: "15rem" }}
            >
              {cardImage ? (
                <img
                  src={URL.createObjectURL(cardImage)}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="white"
                    className="bi bi-image"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                    <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
                  </svg>
                  <span className="text-white">Select image</span>
                </>
              )}
            </label>

            {!cardImage && (
              <span style={{ fontSize: "12px" }} className="text-danger">
                image is required.
              </span>
            )}

            <TextField
              multiline
              rows={1}
              required
              focused
              label="Name"
              fullWidth
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
            />

            <button className="align-self-start px-4 dash-button dash-button-rounded bg-steelblue">
              {loading ? (
                <RotatingLines
                  visible={true}
                  height="20"
                  width="20"
                  color="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                "Upload"
              )}
            </button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

const UpdateDocModel = React.memo(({ id }) => {
  const [open, setOpen] = React.useState(false);
  let { certificates } = useSelector((state) => state.DataSlice);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [cardImage, setCardImage] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [name, setName] = useState("");
  const [decetedOnChange, setDecetedOnChange] = useState(true);

  const ImageOnChange = (e) => {
    setCardImage(e.target.files[0]);
    setDecetedOnChange(false);
  };
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("file", cardImage);
      formData.append("name", name);
      let res = await PutRequestTest(`/api/docs/update/${id}`, formData, "multi");
      dispatch(updateDoc(res.data.doc));
      setName("");
      setCardImage(null);
      setLoading(false);
      setOpen(false);
    } catch (error) {
      setLoading(false);
      alert(error?.message);
    }
  };

  useEffect(() => {
    if (id) {
      let doc = certificates.find((item) => item._id === id);
      setName(doc.name);
      setCurrentImage(doc.path);
    }
  }, [id, open]);

  return (
    <div>
      <button onClick={handleOpen} className="image-upload-btn px-3 text-white">
        Upload New
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={onSubmit} className="d-flex flex-column gap-4">
            <input
              onChange={ImageOnChange}
              style={{ display: "none" }}
              type="file"
              name="card-1-img"
              required={!cardImage && !currentImage ? true : false}
              id="card-1-img"
            />
            <label
              htmlFor="card-1-img"
              className="d-flex w-100 rounded align-items-center justify-content-center flex-column"
              style={{ background: "#ccc", height: "15rem" }}
            >
              {cardImage ? (
                <img
                  src={URL.createObjectURL(cardImage)}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : currentImage ? (
                <img
                  src={currentImage}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="white"
                    className="bi bi-image"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                    <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
                  </svg>
                  <span className="text-white">Select image</span>
                </>
              )}
            </label>

            {cardImage?.image && !currentImage ? (
              <span style={{ fontSize: "12px" }} className="text-danger">
                Image is required
              </span>
            ) : (
              ""
            )}

            <TextField
              multiline
              rows={1}
              required
              focused
              label="Name"
              fullWidth
              onChange={(e) => {
                setName(e.target.value);
                setDecetedOnChange(false);
              }}
              value={name}
            />

            <button
              disabled={decetedOnChange}
              className="align-self-start px-4 dash-button dash-button-rounded bg-steelblue"
            >
              {loading ? (
                <RotatingLines
                  visible={true}
                  height="20"
                  width="20"
                  color="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                "Upload"
              )}
            </button>
          </form>
        </Box>
      </Modal>
    </div>
  );
});

export default Certificates;
