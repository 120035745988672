import React, { useEffect } from "react";
import "./cold_chain_solutions.css";
import NavigateBack from "../ExpertiseInner/NavigateBack";
import PageDescription from "../Exp_inner_Components/PageDescription";
import Footer from "../AboutSec/Footer";
import Section2 from "./Section2";
import WhyChooseUs4Grid from "../Exp_inner_Components/WhyChooseUs4Grid";
import PageHeroSection from "../Exp_inner_Components/PageHeroSection";

let cards1 = [
  {
    title: "Industry Leadership: ",
    paragraph: `With a proven track record of excellence, BAC is a trusted leader in Cold Chain Storage solutions worldwide.`,
  },
  {
    title: "Innovation:",
    paragraph: `We continuously invest in research and development to stay at the forefront of technological advancements in HVACR and Cold Chain management.`,
  },
  {
    title: "Global Reach: ",
    paragraph: `Whether you operate locally or globally, our extensive network ensures prompt service and support wherever your business takes you.
    `,
  },
  {
    title: "Customer Satisfaction: ",
    paragraph: `Our success is measured by the success of our customers. We are committed to delivering solutions that exceed expectations and drive tangible results for your business.`,
  },
];

const ComfortCooling = () => {
  useEffect(() => {
    document.body.classList.add("menu-is-closed");
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavigateBack />
      <PageHeroSection
        parentStyles={"cold_chain_solutions"}
        title={"Cold Chain Solutions"}
        description={` We provide professional Industrial process cold chain
                  solutions as per specific requirement of the process
                  machinery.`}
      />

      <PageDescription
        title={"Welcome to BAC Cold Chain Solutions"}
        description={
          "At BAC, we specialize in providing cutting-edge Cold Chain Storage solutions powered by advanced HVACR Chillers and equipment. With decades of experience and a commitment to innovation, we ensure that your perishable goods are kept at optimal temperatures throughout the supply chain, preserving their quality and extending their shelf life."
        }
      />
      <Section2 />
      <WhyChooseUs4Grid cards={cards1} />
      <Footer />
    </>
  );
};

export default ComfortCooling;
