import React from "react";

const PageHeroSection = ({ parentStyles, title, description }) => {
  return (
    <section className={` ${parentStyles} mb-5`}>
      <div className="container-fluid operat-main d-flex justify-content-center alig-items-center operat-innter">
        <div className="row ">
          <div className="col-12 d-flex justify-content-center alig-items-center operat-innter ">
            <div className="d-flex justify-content-center alig-items-center flex-column operate text-center" data-aos='fade-up'>
              <h1 className="text-white ">{title}</h1>
              {description && <p className="text-white">
                {description}
              </p>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageHeroSection;
