import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Routes from "./routes";
import "./dashboard.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
const Sidebar = ({ isOpen, setIsOpen }) => {
  const {user,setUser} = useContext(AuthContext);
  const handleSideBar = () => setIsOpen(!isOpen);
  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove("Token");
    setUser(null);
    navigate("/login");
  };

  return (
    <div
      className={`bg-steelblue  ${
        isOpen ? "sidebar-container-sm" : "sidebar-container"
      } p-3 py-2`}
    >
      <div className="d-flex flex-column py-3 border-bottom text-white sidebar-header">
        <NavLink
          to={"/dashboard"}
          className={"text-decoration-none text-white"}
        >
          <h5>Admin Panel</h5>
          <span>{user?.email}</span>
        </NavLink>
        <div className="close-sidebar-icon" onClick={handleSideBar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="black"
            className="bi bi-arrow-left-short"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"
            />
          </svg>
        </div>
      </div>

      <div className="pt-4" style={{ minHeight: "66vh" }}>
        <ul className="nav gap-4 sidebar-navigations">
          {Routes.map((item, index) => (
            <li key={index} className={"nav-item rounded "}>
              <NavLink
                to={item.path}
                className={
                  "nav-link text-black d-flex flex-row align-items-center gap-3"
                }
              >
                {item.icon}
                <span className="font-weight-bold">{item.title}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div className="sidebar-footer d-flex flex-column gap-1 text-white">
        <span>Back to page</span>
        <button className="" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
