import React from "react";
import ceo from "./images/CEO 1.png";
import sign from "./images/image 91.png";
function AboutTop() {
  return (
    <>
      <div className="container mt-lg-5">
        <div className="row">
          <div className="col-lg-6 col-sm-12 col-md-12 778 d-flex justify-content-start align-items-center order-lg-1 order-2">
            <div className="">
              <p className="mb-0">Hello !</p>
              <h1 className="text-start fw-bolder">I’m Humair Ahmed</h1>
              <h4>CEO of Brothers Air Conditioning</h4>
              <p
                style={{ fontSize: "12px" }}
                className="d-flex flex-column gap-3"
              >
                <span>
                  I am proud to share that in a short time, our team has
                  achieved great success and impressive growth. Brothers Air
                  Conditioning is a well-known company, becoming one of the
                  leading service providers in the region. Our company profile
                  showcases our achievements and project references from 18
                  years of HVAC experience in Pakistan and around the globe.
                </span>
                <span>
                  We bring a wealth of experience to our work, built over many
                  years. Our company is dedicated to honesty, transparency, and
                  respect for our clients. As an independent company, we focus
                  on delivering customer satisfaction with the help of our
                  hardworking team of skilled technicians and staff. They are
                  passionate and knowledgeable, always aiming to meet the
                  expectations of our valued clients.
                </span>

                <span>
                  With strong determination, we are committed to meeting our
                  customers' needs and concerns with top-quality HVAC services
                  in Pakistan and around the globe.
                </span>
              </p>
              <div className="d-flex justify-content-start align-items-start flex-column about-btn">
                <img src={sign} alt="" />
              </div>
            </div>
          </div>

          <div
            className="col-lg-6 col-sm-12 col-md-12 d-flex justify-content-end align-items-center order-lg-2 order-1 "
            data-aos="fade-right"
          >
            <div className="Rside w-50 d-flex justify-content-center align-items-center  ">
              <div className="Ceo-img d-flex justify-content-center align-items-center position-relative">
                <img src={ceo} alt="CEO" className="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutTop;
