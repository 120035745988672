import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import SearchIcon from "@mui/icons-material/Search";
import Card from "./components/card";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import logo from "../HomeImages/new-logo.gif";
import CloseIcon from "@mui/icons-material/Close";
import "./bacstore.css";
import { NavLink } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebaseconfig";
import Menu from "./components/Menu";

function Page() {
  const [clicked, setClicked] = React.useState("");
  const [showBar, setShowBar] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [showSearch, setShowSearch] = React.useState(false);

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "storeData"));
      const fetchedData = [];
      querySnapshot.forEach((doc) => {
        fetchedData.push({ id: doc.id, ...doc.data() });
      });
      setData([{ ...fetchedData }]);
    };

    fetchData();
  }, []);

  const SearchItems = () => {
    return data.map((item, itemKey) =>
      Object.keys(item).map((product, productKey) =>
        item[product].brandName
          .toLowerCase()
          .includes(search?.toLocaleLowerCase()) ? (
          <Card
            key={`${itemKey}-${productKey}`}
            company={item[product].brandName}
            sPrice={item[product].price}
            ePrice={item[product].price}
            model={item[product].model}
            product={item[product].productName}
            part_no={item[product].part_no}
            inStock={item[product].inStock}
            onOrder={item[product].onOrder}
            images={item[product].images}
            quantity={item[product].quantity}
            priorSale={item[product].priorSale}
          />
        ) : item[product].model
            .toLowerCase()
            .includes(search?.toLocaleLowerCase()) ? (
          <Card
            key={`${itemKey}-${productKey}`}
            company={item[product].brandName}
            sPrice={item[product].price}
            ePrice={item[product].price}
            model={item[product].model}
            product={item[product].productName}
            part_no={item[product].part_no}
            inStock={item[product].inStock}
            onOrder={item[product].onOrder}
            images={item[product].images}
            quantity={item[product].quantity}
            priorSale={item[product].priorSale}
          />
        ) : item[product].productName
            .toLowerCase()
            .includes(search?.toLocaleLowerCase()) ? (
          <Card
            key={`${itemKey}-${productKey}`}
            company={item[product].brandName}
            sPrice={item[product].price}
            ePrice={item[product].price}
            model={item[product].model}
            product={item[product].productName}
            part_no={item[product].part_no}
            inStock={item[product].inStock}
            onOrder={item[product].onOrder}
            images={item[product].images}
            quantity={item[product].quantity}
            priorSale={item[product].priorSale}
          />
        ) : item[product].part_no
            .toLowerCase()
            .includes(search?.toLocaleLowerCase()) ? (
          <Card
            key={`${itemKey}-${productKey}`}
            company={item[product].brandName}
            sPrice={item[product].price}
            ePrice={item[product].price}
            model={item[product].model}
            product={item[product].productName}
            part_no={item[product].part_no}
            inStock={item[product].inStock}
            onOrder={item[product].onOrder}
            images={item[product].images}
            quantity={item[product].quantity}
            priorSale={item[product].priorSale}
          />
        ) : (
          ""
        )
      )
    );
  };

  React.useEffect(() => {
    window.document.body.classList.remove("menu-is-open");
  }, []);

  let GetOtherBrands = () => {
    let brands = ["Daikin", "York", "SKM", "Trane", "Carrier", "Zamil"];

    let parseBrandName = (name) => {
      if (name.includes("/")) {
        let a = name.split("/")[0];
        return a;
      } else {
        return name;
      }
    };

    return data.map((item, itemKey) =>
      Object.keys(item).map((product, productKey) =>
        !brands.includes(parseBrandName(item[product].brandName)) ? (
          <Card
            key={`${itemKey}-${productKey}`}
            company={item[product].brandName}
            sPrice={item[product].price}
            ePrice={item[product].price}
            model={item[product].model}
            product={item[product].productName}
            part_no={item[product].part_no}
            inStock={item[product].inStock}
            onOrder={item[product].onOrder}
            images={item[product].images}
            quantity={item[product].quantity}
          />
        ) : (
          ""
        )
      )
    );
  };

  return (
    <>
      <div className="d-flex flex-column">
        <div
          style={{ height: 170, width: "100%" }}
          className="bac-store-header px-5"
        >
          <NavLink to={"/"}>
            <img
              src={logo}
              className="responsive"
              alt="Logo"
              style={{
                width: 300,
                height: "100%",
                objectFit: "contain",
                marginLeft: "-95px",
              }}
            />
          </NavLink>

          <div
            className="d-flex fkex-row align-items-center justify-content-end px-0 sm:pr-6"
            style={{ width: "100%", height: "40%" }}
          >
            {showSearch ? (
              <div className="store-search-input-container">
                <SearchIcon
                  style={{ left: 10 }}
                  className="position-absolute"
                />
                <input
                  className="
                  store-search-input
                   d-block bg-white w-100 border rounded"
                  placeholder="Search.."
                  type="text"
                  name="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            ) : (
              ""
            )}

            <div className="hidden sm:block">
              <button
                onClick={() => {
                  setShowSearch(!showSearch);
                  if (showSearch) {
                    setSearch("");
                  }
                }}
                className="p-2 store-search-btn"
                style={{ backgroundColor: "#005471" }}
              >
                {showSearch ? (
                  <CloseIcon style={{ fontSize: 32, color: "#fff" }} />
                ) : (
                  <SearchIcon style={{ fontSize: 32, color: "#fff" }} />
                )}{" "}
              </button>
            </div>
          </div>

          <Menu />
        </div>

        <div className="d-flex flex-row">
          <div
            className={` ${showBar ? "toggle" : "cstHidden"}`}
            style={{ width: 260 }}
          >
            <div
              className="position-fixed d-flex justify-content-center products-filter-container"
              style={{
                color: "#fff",
                backgroundColor: "#005471",
                width: 220,
              }}
            >
              <List style={{ width: "100%" }}>
                {[
                  "All",
                  "Daikin",
                  "York",
                  "SKM",
                  "Trane",
                  "Carrier",
                  "Zamil",
                  "Miscellaneous",
                ].map((text, index) => (
                  <ListItem style={{ width: "100%" }} key={text} disablePadding>
                    <ListItemButton
                      style={{ width: "100%" }}
                      onClick={() => setClicked(text)}
                    >
                      <ListItemText
                        style={{ textAlign: "center" }}
                        primary={text}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>

          <div className={`cstShow flex`} style={{ zIndex: 1 }}>
            <button
              onClick={() => {
                setShowBar(!showBar);
              }}
              className={`p-1 rounded position-fixed ${
                showBar ? "cstMleft" : ""
              }`}
              style={{ top: 300, border: "1px solid black" }}
            >
              {" "}
              {showBar ? (
                <KeyboardArrowLeftIcon style={{ fontSize: 35 }} />
              ) : (
                <KeyboardArrowRightIcon style={{ fontSize: 35 }} />
              )}{" "}
            </button>
          </div>

          <div className="p-3 bac-store-cards-grid" style={{ width: "100%" }}>
            {search === ""
              ? clicked === "" || clicked === "All"
                ? data.map((item, itemKey) =>
                    Object.keys(item).map((product, productKey) => (
                      <Card
                        key={`${itemKey}-${productKey}`}
                        company={item[product].brandName}
                        sPrice={item[product].price}
                        ePrice={item[product].price}
                        model={item[product].model}
                        product={item[product].productName}
                        part_no={item[product].part_no}
                        inStock={item[product].inStock}
                        onOrder={item[product].onOrder}
                        images={item[product].images}
                        quantity={item[product].quantity}
                        priorSale={item[product].priorSale}
                      />
                    ))
                  )
                : clicked === "Miscellaneous"
                ? GetOtherBrands()
                : data.map((item, itemKey) =>
                    Object.keys(item).map(
                      (product, productKey) =>
                        item[product].brandName
                          .toLowerCase()
                          .includes(clicked.toLowerCase()) && (
                          <Card
                            key={`${itemKey}-${productKey}`}
                            company={item[product].brandName}
                            sPrice={item[product].price}
                            ePrice={item[product].price}
                            model={item[product].model}
                            product={item[product].productName}
                            part_no={item[product].part_no}
                            inStock={item[product].inStock}
                            onOrder={item[product].onOrder}
                            images={item[product].images}
                            quantity={item[product].quantity}
                            priorSale={item[product].priorSale}
                          />
                        )
                    )
                  )
              : SearchItems()}
          </div>

          <div
            className="d-flex align-items-center"
            style={{ height: "87vh", zIndex: 1 }}
          >
            <button
              className="position-fixed p-2"
              style={{
                border: "1px solid black",
                right: "30px",
                bottom: "20px",
                borderRadius: "50px",
                background: "transparent",
              }}
            >
              <NavLink to="https://wa.me/923219231297" target="_blank">
                <WhatsAppIcon style={{ fontSize: "40", color: "#2ab13f" }} />
              </NavLink>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Page;
