import React from "react";

const Section4 = () => {
  return (
    <div className="container mb-lg-5" style={{ overflow: "hidden" }}>
      <div className="row flex-wrap">
        <div className="col-xl-6 ">
          <h1 data-aos="fade-right" className="fw-bolder text-start">
            Get in touch with us for personalized assistance and support
          </h1>
          <p className=" w-100" data-aos="fade-left">
            We're here to help! Reach out to us at <b>info@bacr.com.pk</b> or
            call us at <b>+92-21-34322501/502</b> Our team looks forward to
            assisting you with any information you need.
          </p>
        </div>
        <div className="col-xl-6 ">
          <div className="map-inner">
            <iframe
              className=" map"
              title="Over Location"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14471.960091650359!2d67.154188!3d24.9324098!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb3394437c0016f%3A0x382f7598d01d25dc!2sBrothers%20Air%20Conditioning!5e0!3m2!1sen!2s!4v1704742670572!5m2!1sen!2s"
              width="100%"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
