import { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import "../New Components/dashboard.css";
import BrandOffering from "../New Components/BrandOffering";
import { Routes, Route } from "react-router-dom";
import Employees from "../New Components/Employees";
import Certificates from "../New Components/Certificates";
import BacStore from "../New Components/BacStore";
import EditEmployee from "../New Components/EditEmployee";
import AddEmployee from "../New Components/AddEmployee";
import AddCard from "../New Components/AddCard";
import EditCardDetails from "../New Components/EditCard";
import AddProduct from "../New Components/AddProduct";
import EditProduct from "../New Components/EditProduct";

export default function Admin() {
  let Navigate = useNavigate();

  const token = Cookies.get("Token");
  useEffect(() => {
    if (!token) {
      Navigate("/login");
    }
  }, []);

  return (
    <>
      <Routes>
        {token ? (
          <>
            <Route path="/" element={<BrandOffering />} />
            <Route path="/add-card" element={<AddCard />} />
            <Route path="/edit-card/:id" element={<EditCardDetails />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/edit-employee/:id" element={<EditEmployee />} />
            <Route path="/new-employee" element={<AddEmployee />} />
            <Route path="/certificates" element={<Certificates />} />
            <Route path="/bacstore" element={<BacStore />} />
            <Route path="/add-product" element={<AddProduct />} />
            <Route path="/edit-product/:id" element={<EditProduct />} />
          </>
        ) : (
          ""
        )}
      </Routes>
    </>
  );
}
