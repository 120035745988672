import React, { useEffect } from "react";
import loderrn from "./assets/loadingScreen.gif";

function LoadScreen({ dis_none }) {

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });

    if (!dis_none) {
      document.body.style.height = "100%";
      document.body.style.width = "100%";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "100%";
      document.body.style.width = "100%";
      document.body.style.overflow = "auto";
    }
  }, [dis_none]);

  return (
    <div
      className={`container-fluid loder_main ${
        dis_none === true ? "Loder-main-dis" : ""
      } `}
    >
      <div className="row">
        <div className="col-12 d-flex justify-content-center align-items-center flex-column  ">
          <div className="loader  d-flex justify-content-center align-items-center flex-column ">
            <img
              alt=""
              src={loderrn}
              style={{
                width: "100%",
                height: "auto",
                backgroundColor: "transparent",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadScreen;
