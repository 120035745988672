import React, { useState } from "react";
import "./card.css";
import ProductGallery from "./ProductGallery";

function Card({
  priorSale,
  company,
  product,
  ePrice,
  model,
  part_no,
  inStock,
  images,
  onOrder,
  quantity,
}) {
  const [showModel, setShowModel] = useState(false);

  const handleClick = () => {
    setShowModel(!showModel);
  };

  return (
    <>
      <ProductGallery
        isOpen={showModel}
        setIsOpen={setShowModel}
        images={images}
        company={company}
        product={product}
        ePrice={ePrice}
        model={model}
        part_no={part_no}
        inStock={inStock}
        onOrder={onOrder}
        quantity={quantity}
      />

      <div
        className="rounded custom bac-store-grid-card"
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      >
        <div
          className="d-flex justify-content-center px-3 py-3  position-relative"
          style={{ height: "15rem", width: "100%" }}
        >
          {images?.length ? (
            <img
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
              src={images[0]}
              alt="machine"
            />
          ) : (
            ""
          )}

          {priorSale ? (
            <span className="priorSale_text uppercase">
              Subject to prior sale
            </span>
          ) : (
            ""
          )}
        </div>

        <div className="p-3 d-flex flex-column w-100">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <h5 className="">{product}</h5>
            {inStock ? (
              <span
                style={{ color: "green", fontSize: "14px", fontWeight: "500" }}
              >
                In Stock
              </span>
            ) : onOrder ? (
              <span
                style={{ color: "green", fontSize: "14px", fontWeight: "500" }}
              >
                On Order Only
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="d-flex flex-row align-items-center justify-content-between">
            <span style={{ fontSize: "14px" }}>Brand</span>
            <h6 className="">{company}</h6>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between">
            <span style={{ fontSize: "14px" }}>Model Number</span>
            <h6 className="px-2 py-1 rounded text-black">{model}</h6>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between">
            <span style={{ fontSize: "14px" }}>Part Number</span>
            <h6 className="px-2 py-1 rounded text-black">{part_no}</h6>
          </div>
          {inStock ? (
            <div className="d-flex flex-row align-items-center justify-content-between">
              <span style={{ fontSize: "14px" }}>Quantity</span>
              <h6 className="px-2 py-1 rounded text-black">{quantity}</h6>
            </div>
          ) : (
            ""
          )}
          <div className="d-flex flex-row align-items-center justify-content-between gap-2">
            <span style={{ fontSize: "14px" }}>Price</span>
            <h6 className="bg-steelblue px-2 py-1 rounded text-white">
              {ePrice}
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;
