import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { plusIcon } from "../New Components/routes";
import { useForm } from "react-hook-form";
import { PostRequestTest } from "../Actions/Api_Requests";
import { useDispatch } from "react-redux";
import { addChildCard } from "../../Redux/reducers/DataSlice";
import { RotatingLines } from "react-loader-spinner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
};

const NewBrandForm = ({ parentId }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = React.useState(false);
  const [cardImage, setCardImage] = React.useState(null);
  const [newFeature, setNewFeature] = React.useState("");
  const [features, setFeatures] = React.useState([]);
  const [fieldErrors, setFieldError] = React.useState({
    logo: null,
    bg_img: null,
    features: null,
  });

  const dispatch = useDispatch();

  const defaultValues = {
    title: "",
    description: "",
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const ImageOnChange = (e) => {
    setCardImage(e.target.files[0]);
    if (fieldErrors?.logo) {
      setFieldError((pre) => ({ ...pre, logo: null }));
    }
  };

  const handleAddNewFeature = () => {
    if (newFeature?.trim()?.length > 0) {
      setFeatures((e) => [...e, newFeature]);
      setNewFeature("");
    }
  };

  const handleRemoveFeature = (feature) => {
    let newArr = features?.filter(
      (item) => item?.toLowerCase() !== feature?.toLowerCase()
    );
    setFeatures(newArr);
  };

  const onSubmit = async (e) => {
    try {
      if (!cardImage) {
        setFieldError((pre) => ({
          ...pre,
          logo: "Brand Logo image is required",
        }));
        setLoading(false);
        return;
      }

      if (!features?.length) {
        setFieldError((pre) => ({
          ...pre,
          features: "Please add atleast one point.",
        }));
        setLoading(false);
        return;
      }

      setLoading(true);

      let payload = {
        ...e,
        features,
        logo: cardImage,
      };
      let res = await PostRequestTest(
        `/api/cards/create/child/${parentId}`,
        payload,
        "multi"
      );

      dispatch(addChildCard({ child: res.data.card, parent: parentId }));
      setLoading(false);
      setCardImage(null);
      reset();
      setFeatures([]);
      setFieldError({
        logo: null,
        bg_img: null,
        features: null,
      });
      setNewFeature("");
      setOpen(!open);
    } catch (error) {
      setLoading(false);
      setCardImage(null);
      setFeatures([])
      reset();
      setOpen(!open);
    }
  };

  return (
    <div>
      <button
        onClick={handleOpen}
        className="dash-button rounded px-3 text-white d-flex  bg-steelblue align-items-center gap-1"
      >
        {plusIcon()}
        <span>Add Item</span>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-4"
            style={{
              height: "55vh",
              overflow: "auto",
            }}
          >
            <div className="d-flex flex-column mb-3">
              <label
                htmlFor="card-1-img"
                style={{
                  width: "100px",
                  overflow: "hidden",
                  borderRadius: "5px",
                  height: "70px",
                  cursor: "pointer",
                  background: cardImage ? "white" : "#ccc",
                }}
              >
                <input
                  onChange={ImageOnChange}
                  style={{ display: "none" }}
                  type="file"
                  name="card-1-img"
                  id="card-1-img"
                />
                {cardImage ? (
                  <img
                    alt=""
                    src={URL.createObjectURL(cardImage)}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                ) : (
                  <div className="h-100 d-flex flex-column gap-2 justify-content-center align-items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="white"
                      className="bi bi-image"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                      <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
                    </svg>
                    <span style={{ fontSize: "12px" }} className="text-white">
                      Brand Logo
                    </span>
                  </div>
                )}
              </label>
              {fieldErrors?.logo && !cardImage ? (
                <span style={{ fontSize: "12px", color: "red" }}>
                  Brand Logo Image is required.
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="gap-4 mt-4 d-flex flex-column">
              <TextField
                size={"small"}
                {...register("title", { required: "Title is required" })}
                multiline
                rows={1}
                focused
                id="outlined-basic"
                fullWidth
                required
                label="Title"
                variant="outlined"
              />

              {errors?.title?.message ? (
                <span style={{ fontSize: "12px", color: "red" }}>
                  {errors?.title?.message}
                </span>
              ) : (
                ""
              )}

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row align-items-center gap-3">
                  <TextField
                    size={"small"}
                    value={newFeature}
                    onChange={(e) => {
                      setNewFeature(e.target.value);
                      if (fieldErrors?.features) {
                        setFieldError((pre) => ({ ...pre, features: null }));
                      }
                    }}
                    focused
                    id="outlined-basic"
                    fullWidth
                    type="text"
                    label="Feature"
                    variant="outlined"
                  />

                  <button
                    disabled={newFeature?.length < 1 ? true : false}
                    onClick={handleAddNewFeature}
                    type="button"
                    className="dash-button rounded px-3 text-white d-flex  bg-steelblue align-items-center gap-1"
                  >
                    {plusIcon()}
                  </button>
                </div>

                {fieldErrors?.features ? (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    {fieldErrors?.features}
                  </span>
                ) : (
                  ""
                )}

                <div className="d-flex flex-column gap-2 ">
                  {features.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="row align-items-center rounded mx-0 py-1"
                        style={{
                          backgroundColor: "#ccc",
                        }}
                      >
                        <div className="col-11" style={{ fontSize: "14px" }}>
                          {item?.length > 45 ? (
                            <>{item?.slice(0, 45)}... </>
                          ) : (
                            item
                          )}
                        </div>

                        <div className="col-1">
                          <svg
                            onClick={() => {
                              handleRemoveFeature(item);
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="bi bi-x"
                            style={{ cursor: "pointer" }}
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                          </svg>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="align-self-start mt-4 px-4 dash-button dash-button-rounded bg-steelblue"
            >
              {loading ? (
                <RotatingLines
                  visible={true}
                  height="20"
                  width="20"
                  color="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default NewBrandForm;
