import React, { useEffect, useState } from "react";
import DashboardContainer from "./DashboardContainer";
import { Switch, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../config/firebaseconfig";
import { RotatingLines } from "react-loader-spinner";
import { v1 as uuid } from "uuid";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

const label = { inputProps: { "aria-label": "Switch demo" } };

const EditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [inStock, setInStock] = useState(false);
  const [onOrder, setOnOrder] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [images, setImages] = useState([]);
  const [priorSale, setPriorSale] = useState(false);

  const [currentImages, setCurrentImages] = useState([]);

  const [defaultValues, setDefaultValues] = useState({
    productName: "",
    brandName: "",
    model: "",
    price: "",
    part_no: "",
  });

  const handleDeleteImage = async (index) => {
    try {
      const imageUrl = currentImages[index];
      const filename = imageUrl
        .substring(imageUrl.lastIndexOf("/") + 1)
        .split("?")[0];

      const storageRef = ref(storage, filename);
      await deleteObject(storageRef);

      let newArry = currentImages?.filter((_, id) => id !== index);
      setCurrentImages(newArry);
    } catch (error) {
      alert("Unexpected Error Occured tryagain.");
    }
  };

  const uploadFile = async (file) => {
    if (!file) return;

    let fileName = uuid();

    const storageRef = ref(storage, fileName);

    await uploadBytesResumable(storageRef, file);

    const filePath = `/${fileName}`;

    const fileRef = ref(storage, filePath);

    return await getDownloadURL(fileRef);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let { productName, brandName, model, price, part_no } = defaultValues;

      if (
        !productName?.trim().length ||
        !brandName?.trim().length ||
        !model?.trim().length ||
        !price?.trim().length ||
        !part_no?.trim().length
      ) {
        throw new Error("All fields are required.");
      }

      if (inStock && !quantity) {
        throw new Error("Please add product quantity.");
      }

      if (!images?.length && !currentImages?.length) {
        throw new Error("Product Images are required.");
      }

      let productImages = [];
      if (images.length) {
        const uploadPromises = images.map((item) => uploadFile(item));
        productImages = await Promise.all(uploadPromises);
      }

      const docRef = doc(db, "storeData", id);
      const date = new Date();

      await updateDoc(docRef, {
        ...defaultValues,
        inStock,
        onOrder,
        quantity,
        images: [...currentImages, ...productImages],
        lastModified: date,
        priorSale
      });
      setDefaultValues({
        productName: "",
        brandName: "",
        model: "",
        price: "",
        part_no: "",
      });
      setLoading(false);
      setImages([]);
      setCurrentImages([]);
      setPriorSale(false)
      navigate("/dashboard/bacstore");
    } catch (error) {
      setLoading(false);
      alert(error?.message ?? "Unexpected Error occured please tryagain.");
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchProduct = async () => {
      setLoadingData(true);
      try {
        const docRef = doc(db, "storeData", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          let data = docSnap.data();
          setDefaultValues({
            productName: data?.productName,
            brandName: data?.brandName,
            model: data?.model,
            price: data?.price,
            part_no: data?.part_no,
          });

          setInStock(data?.inStock);
          setOnOrder(data?.onOrder);
          setCurrentImages(data?.images);
          setQuantity(data?.quantity);
          setPriorSale(data?.priorSale);
        } else {
          throw new Error("");
        }
      } catch (err) {
        setFetchError(true);
      } finally {
        setLoadingData(false);
      }
    };

    if (id) {
      fetchProduct();
    } else {
      setLoadingData(false);
      setFetchError(true);
    }
  }, [id]);

  return (
    <DashboardContainer title={"BAC Store Details"}>
      <div className="mt-5">
        {loadingData ? (
          <h4>Processing...</h4>
        ) : fetchError ? (
          <h4>Unexpected Error Occuared please try again.</h4>
        ) : id ? (
          <form className="w-100" onSubmit={handleSubmit}>
            <div className="bac-add-product-grid">
              <div className="">
                <TextField
                  focused
                  multiline
                  rows={1}
                  id="outlined-basic"
                  fullWidth
                  label="Product Name"
                  required
                  variant="outlined"
                  defaultValue={defaultValues.productName}
                  values={defaultValues.productName}
                  onChange={(e) => {
                    setDefaultValues((pre) => ({
                      ...pre,
                      productName: e.target.value,
                    }));
                  }}
                />
                <TextField
                  focused
                  multiline
                  rows={1}
                  id="outlined-basic"
                  fullWidth
                  required
                  label="Brand Name"
                  variant="outlined"
                  defaultValue={defaultValues.brandName}
                  values={defaultValues.brandName}
                  onChange={(e) => {
                    setDefaultValues((pre) => ({
                      ...pre,
                      brandName: e.target.value,
                    }));
                  }}
                />
                <TextField
                  focused
                  multiline
                  rows={1}
                  id="outlined-basic"
                  fullWidth
                  required
                  label="Part Number"
                  variant="outlined"
                  defaultValue={defaultValues.part_no}
                  values={defaultValues.part_no}
                  onChange={(e) => {
                    setDefaultValues((pre) => ({
                      ...pre,
                      part_no: e.target.value,
                    }));
                  }}
                />

                <label
                  htmlFor="instock"
                  className="d-flex flex-row gap-2 align-items-center"
                >
                  <span>In Stock</span>
                  <Switch
                    id="instock"
                    title="In Stock"
                    checked={inStock}
                    onChange={() => {
                      setInStock(!inStock);
                    }}
                    {...label}
                  />
                </label>

                {inStock ? (
                  <>
                    <TextField
                      focused
                      id="outlined-basic"
                      fullWidth
                      multiline
                      rows={1}
                      type="text"
                      label="Quantity"
                      required={inStock}
                      variant="outlined"
                      defaultValue={quantity}
                      value={quantity}
                      onChange={(e) => {
                        setQuantity(e.target.value);
                      }}
                    />

                    <label
                      htmlFor="priorSale"
                      className="d-flex flex-row gap-2 align-items-center"
                    >
                      <span>Subject to prior sale</span>
                      <Switch
                        id="priorSale"
                        title="Subject to prior sale"
                        checked={priorSale}
                        onChange={() => {
                          setPriorSale(!priorSale);
                        }}
                        {...label}
                      />
                    </label>
                  </>
                ) : (
                  ""
                )}

                <label
                  htmlFor="onorder"
                  className="d-flex flex-row gap-2 align-items-center"
                >
                  <span>On Order Only</span>
                  <Switch
                    id="onorder"
                    title="On Order Only"
                    checked={onOrder}
                    onChange={() => {
                      setOnOrder(!onOrder);
                    }}
                    {...label}
                  />
                </label>
              </div>
              <div className="">
                <TextField
                  focused
                  multiline
                  rows={1}
                  id="outlined-basic"
                  fullWidth
                  label="Model"
                  required
                  values={defaultValues.model}
                  defaultValue={defaultValues.model}
                  onChange={(e) => {
                    setDefaultValues((pre) => ({
                      ...pre,
                      model: e.target.value,
                    }));
                  }}
                  variant="outlined"
                />
                <TextField
                  focused
                  multiline
                  rows={1}
                  id="outlined-basic"
                  fullWidth
                  required
                  label="Price"
                  variant="outlined"
                  values={defaultValues.price}
                  defaultValue={defaultValues.price}
                  onChange={(e) => {
                    setDefaultValues((pre) => ({
                      ...pre,
                      price: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>

            <div className="d-flex flex-column mt-4">
              <h5>Product Images</h5>
              <div className="d-flex flex-wrap flex-row align-items-center gap-4 mt-2">
                {images?.length
                  ? images?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex flex-row align-items-center justify-content-center"
                          style={{
                            background: "#ccc",
                            height: "150px",
                            width: "150px",
                            borderRadius: "8px",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-x"
                            viewBox="0 0 16 16"
                            onClick={() => {
                              let newArry = images?.filter(
                                (_, id) => id !== index
                              );
                              setImages(newArry);
                            }}
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              zIndex: 1,
                              cursor: "pointer",
                              background: "white",
                              borderRadius: "50px",
                            }}
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                          </svg>
                          <img
                            alt=""
                            style={{
                              width: "100%",
                              objectFit: "contain",
                              height: "100%",
                            }}
                            src={URL.createObjectURL(item)}
                          />
                        </div>
                      );
                    })
                  : ""}
                {currentImages?.length
                  ? currentImages?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex flex-row align-items-center justify-content-center"
                          style={{
                            background: "#ccc",
                            height: "150px",
                            width: "150px",
                            borderRadius: "8px",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            className="bi bi-x"
                            viewBox="0 0 16 16"
                            onClick={() => {
                              handleDeleteImage(index);
                            }}
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              zIndex: 1,
                              cursor: "pointer",
                              background: "white",
                              borderRadius: "50px",
                            }}
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                          </svg>
                          <img
                            alt=""
                            style={{
                              width: "100%",
                              objectFit: "contain",
                              height: "100%",
                            }}
                            src={item}
                          />
                        </div>
                      );
                    })
                  : ""}

                <label
                  htmlFor="product-image"
                  className="d-flex flex-row align-items-center justify-content-center"
                  style={{
                    background: "#ccc",
                    height: "150px",
                    width: "150px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                >
                  <AddIcon />
                  <input
                    style={{
                      display: "none",
                    }}
                    id="product-image"
                    type="file"
                    multiple
                    onChange={(e) => {
                      setImages((pre) => [...pre, ...e.target.files]);
                    }}
                  />
                </label>
              </div>
            </div>

            <button
              type="button"
              onClick={() => {
                navigate("/dashboard/bacstore");
              }}
              className="align-self-start mt-4 px-4 dash-button dash-button-rounded bg-steelblue mx-2"
            >
              Cancel
            </button>

            <button
              type="submit"
              className="align-self-start mt-4 px-4 dash-button dash-button-rounded bg-steelblue"
            >
              {loading ? (
                <RotatingLines
                  visible={true}
                  height="20"
                  width="20"
                  color="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                "Submit"
              )}
            </button>
          </form>
        ) : (
          ""
        )}
      </div>
    </DashboardContainer>
  );
};

export default EditProduct;

const AddIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="currentColor"
      className="bi bi-plus-square"
      viewBox="0 0 16 16"
    >
      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
    </svg>
  );
};
