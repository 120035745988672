import React, { Suspense, useEffect, useState } from "react";
import About2 from "./About2";
import About4 from "./About4";
import Navbar from "../Navbar";
import Reviews from "./Reviews.js";
import Footer from "./Footer";
import AboutTop from "./AboutTop.js";
import "./css/TeamImg.css";
import SliderAbout from "./SliderAbout.js";
import EmployeesCards from "./New folder/EmployeesCards.js";

function AboutSec() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(window.innerWidth >= 660);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isVisible]);

  return (
    <>
      <Navbar />
      <AboutTop />
      <Suspense>
        <div className="container my-5">
          <div className="row my-5">
            <div className="col-lg-12 col-sm-12 col-md-12 mt-5 ">
              <div className="about text-center d-flex justify-content-center align-items-center flex-column">
                <h2 data-aos="fade-right">About Us</h2>
                <h1 data-aos="fade-right" className="text-center">
                  Our Dedicated <br />
                  Team of Expert
                </h1>
                <p
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom "
                  className="col-lg-8 col-sm-12"
                >
                  Backed by a dedicated team of highly skilled professionals
                  equipped with cutting-edge tools, we are poised to tackle any
                  HVACR challenge with expertise and precision.
                </p>
              </div>
            </div>

            <EmployeesCards />
          </div>
        </div>
      </Suspense>

      <Suspense>
        <section className="about2_sec">
          <About2 />
        </section>
      </Suspense>
      
      <Suspense>
        <About4 />
      </Suspense>

      <Suspense>
        <Reviews />
        <SliderAbout />
      </Suspense>

      <Footer />
    </>
  );
}

export default AboutSec;
