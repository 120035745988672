import React from "react";
import "./exp_inner_comps.css"

const ImgTextGrid = ({ index, item }) => {
  return (
    <div className="_img_text_grid" key={index}>
      {item?.imgLeft && (
        <div className="_img_left rounded" data-aos="fade-right">
          <img alt="" src={item.img} />
        </div>
      )}
      <div className="_content_div" data-aos={item?.imgLeft ? 'fade-left' : 'fade-right'}>
        {item?.title && <h2>{item.title}</h2>}
        {item?.paragraph && <p>{item.paragraph}</p>}
      </div>

      {!item?.imgLeft && (
        <div className="_img_right"  data-aos="fade-left">
          <img alt="" src={item.img} />
        </div>
      )}
    </div>
  );
};

export default ImgTextGrid;
