import React, { useEffect, useState } from "react";
import DashboardContainer from "./DashboardContainer";
import Plus from "../Images/+.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DeleteRequest, GetRequest } from "../Actions/Api_Requests";
import { setEmployees, deleteEmployee } from "../../Redux/reducers/DataSlice";
import { RotatingLines } from "react-loader-spinner";
import swal from "sweetalert";

const Employees = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let { employees } = useSelector((state) => state.DataSlice);
  let [isDeleting, setIsDeleting] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const handleAddNew = () => {
    navigate("/dashboard/new-employee");
  };

  let fetchEmployees = async () => {
    try {
      let res = await GetRequest("/api/employees");
      dispatch(setEmployees(res?.data?.employees));
    } catch (error) {
      swal("Opps...", "Something went wrong, while retriving data.")
    }
  };

  useEffect(() => {
    if (!employees?.length) {
      fetchEmployees();
    }
  }, []);

  const deleteEmployeehandler = async (id) => {
    setIsDeleting(true);
    setDeleteIndex(id);
    try {
      await DeleteRequest(`/api/employe/delete/${id}`);
      dispatch(deleteEmployee(id));
      setIsDeleting(false);
      setDeleteIndex(null);
    } catch (error) {
      setIsDeleting(false);
      setDeleteIndex(null);
      alert(error.message);
    }
  };

  return (
    <DashboardContainer title={"Employees"}>
      <div className="employees-container">
        {employees?.map((item, index) => (
          <div className="shadow employees-card py-3 rounded " key={index}>
            <div className="employees-card-img rounded">
              <img src={item?.image?.path} alt="..." />
            </div>
            <h5 className="text-center font-weight-bold">{item?.name}</h5>
            <div className="d-flex flex-row align-items-center justify-content-center gap-2">
              <NavLink
                to={`/dashboard/edit-employee/${item?._id}`}
                className="image-upload-btn px-5 text-white text-decoration-none"
              >
                Edit
              </NavLink>
              <button
                onClick={() => {
                  deleteEmployeehandler(item?._id);
                }}
                disabled={isDeleting}
                className="image-delete-btn px-4 text-white"
              >
                {isDeleting && item?._id === deleteIndex ? (
                  <RotatingLines
                    visible={true}
                    height="20"
                    width="20"
                    color="white"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        ))}

        <div
          className="shadow rounded employees-card py-3 rounded bg-steelblue"
          onClick={handleAddNew}
        >
          <img src={Plus} className="" alt="..." />
          <h2 className="text-center text-white">Add Employe</h2>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default Employees;
