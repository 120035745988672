import React, { useState } from "react";
import "./css/Project.css";
import { useNavigate } from "react-router-dom";
import ProjectDetailsModel from "./ReuseableComponents/ProjectDetailsModel";
import Img1 from "./ProjectImages/napa.png";
import Img2 from "./ProjectImages/johsoncontrols.jpeg";
import Img3 from "./ProjectImages/albaraka.jpeg";
import Img4 from "./ProjectImages/aseptic.png";
import Img5 from "./ProjectImages/tufail.png";
import Img6 from "./ProjectImages/pso.jpeg";
import Img7 from "./ProjectImages/regent.jpg";
import Img8 from "./ProjectImages/ugarit.png";
import Img9 from "./ProjectImages/union.png";
import Img10 from "./ProjectImages/zarghun.png";
import Img11 from "./ProjectImages/hashim.jpeg";


let projects = [
  {
    img:Img1,
    name: "Zia Mohyeddin Theatre HVAC System Project",
    nodes: {
      client: "NAPA",
      location: ["Karachi, Pakistan"],
      project_name: "Zia Mohyeddin Theatre Project",
      job_nature:
        "From design to commissioning, Brothers Air Conditioning delivered a seamless and efficient HVAC system for Zia Mohyeddin Theatre.",
      brand: ["York YLAA  0457 HE (120 Tons)"],
    },
  },
  {
    img:Img2,
    name: "Johnson Controls",
    nodes: {
      client: "Johnson Controls",
      location: [
        "Kabul, Afghanistan Dammam, Saudi Arabia",
        "Kenya, Nairobi Karachi, Pakistan",
        "Nooriabad, Pakistan",
      ],
      project_name: "Different Projects",
      job_nature:
        "Different types of jobs including installation, commissioning, supply, controls and programming.",
      brand: [
        "York YMA 1050",
        "York YCUL 0331",
        "York DMS 260",
        "York DMS 340",
        "York DMS 480",
      ],
    },
  },
  {
    img:Img3,
    name: "Al Baraka",
    nodes: {
      client: "Al Baraka",
      location: ["Karachi, Pakistan"],
      project_name: "Al Baraka Project",
      job_nature: "Supply & Commissioning with Warranty",
      brand: ["Carrier 30 XA 0252 (70 Tons)"],
    },
  },
  {
    img:Img4,
    name: "Popular Aseptic Packaging Pvt. Ltd.",
    nodes: {
      client: "Popular Aseptic Packaging Pvt. Ltd.",
      location: ["Nooriabad, Pakistan"],
      project_name: "Davis Plant Project",
      job_nature:
        "Our job included importing, supplying, and commissioning used Trane and Carrier water-cooled chillers, AHUs, panels, and VFDs for an efficient air conditioning and process cooling system.",
      brand: [
        "Trane RTHD Water-Cooled Screw Chiller (400 Tons X 2)",
        "Trane CVGF Water-Cooled Centrifugal Chiller (550 Tons)",
        "Carrier 30 HXC 120 Chiller (120 Tons)",
        "Carrier 30 HXC 090 Chiller (90 tons)",
      ],
    },
  },
  {
    img:Img5,
    name: "Tufail Chemicals",
    nodes: {
      client: "Tufail Chemicals",
      location: ["Karachi, Pakistan"],
      project_name: "Surfactants Plant Project",
      job_nature:
        "We supplied and commissioned a used Carrier water-cooled chiller, highlighting our commitment to cost-effective, sustainable solutions.",
      brand: ["Carrier 30 HXC 090 (90 Tons)"],
    },
  },
  {
    img:Img6,
    name: "PSO House",
    nodes: {
      client: "PSO House",
      location: ["Karachi, Pakistan"],
      project_name: "PSO House Project",
      job_nature: "Operation and Maintenance",
      brand: ["YT and YK water-cooled centrifugal chiller"],
    },
  },
  {
    img:Img7,
    name: "Regent Plaza",
    nodes: {
      client: "Regent Plaza",
      location: ["Karachi, Pakistan"],
      project_name: "Regent Plaza Project",
      job_nature: "Supply and Commissioning with warranty",
      brand: ["Carrier 30 HXC 310 (310 Tons)"],
    },
  },
  {
    img:Img8,
    name: "Ugarit Pharma",
    nodes: {
      client: "Ugarit Pharma",
      location: ["Aleppo", "Syria"],
      project_name: "Aleppo, Syria Project",
      job_nature: "Commissioning only",
      brand: ["LSK Dual fuel direct-fired absorption chiller"],
    },
  },
  {
    img:Img9,
    name: "Union Paper Mills",
    nodes: {
      client: "M.A.H.Y. Khoory & Co. L.L.C",
      location: ["Dubai", "UAE"],
      project_name: "Union Paper Mills Project",
      job_nature: "Commissioning only",
      brand: ["Trane Absorption Chiller"],
    },
  },
  {
    img:Img10,
    name: "Zarghun Central Gas Processing Facility",
    nodes: {
      client: "Kokken",
      location: ["Zarghun, Baluchistan"],
      project_name: "Zarghun Processing Plant Project",
      job_nature: "Commissioning only",
      brand: ["Propane-based refrigeration plant"],
    },
  },
  {
    img:Img11,
    name: "Hashim Medical City Hospital",
    nodes: {
      client: "Hashim Medical City Hospital",
      location: ["Hyderabad, Pakistan"],
      project_name: "Hashim Medical Project",
      job_nature: "Supply and Commissioning with Warranty",
      brand: ["Carrier 30 HXC 120 (120 Tons)"],
    },
  },
];

function Proj3() {
  const [nationState] = useState(false);
  const navigate = useNavigate();

  const ToNation_Porject = () => {
    navigate("/Projects/NationWide");
  };

  const To_oversease_pROJECT = () => {
    navigate("/Projects/OverseasePorject");
  };

  const To_Client_projects = () => {
    navigate("/Projects/ClientProject");
  };

  const toziaProject = () => {
    navigate("/Zia_Mohd_project");
  };

  const toLKabul = () => {
    navigate("/Projects/Oversies/Kabul");
  };

  const toDevis = () => {
    navigate("/Projects/DevisPlant");
  };

  const toPSO = () => {
    navigate("/Projects/PSO");
  };

  const toTufail = () => {
    navigate("/Projects/NationWide/TufailCehmiacls");
  };

  const toUgrait = () => {
    navigate("/Projects/Oversies/Ugarit");
  };
  const toUnion = () => {
    navigate("/Projects/Oversies/UnionPaper");
  };
  const toZargun = () => {
    navigate("/Projects/Oversies/Zarghun");
  };

  return (
    <>
      <div className="container proj-conatiner ">
        <div className="row">
          <div className="col-lg-12">
            <div className="btn-main-div">
              <div className="  d-flex justify-content-center align-items-center">
                <button className="btn-gal  ">All Projects</button>
                <button
                  className={`btn-gal ${nationState === true ? "active1" : ""}`}
                  onClick={ToNation_Porject}
                >
                  Nation Wide Projects
                </button>
                <button className="btn-gal " onClick={To_oversease_pROJECT}>
                  Overseas Projects
                </button>
                <button className="btn-gal " onClick={To_Client_projects}>
                  Clients{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container  my-5 ">
        <div className="row my-5">
          {projects.map((item, index) => (
            <ProjectDetailsModel item={item} key={index} id={index} />
          ))}
          {/* <div className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center  ">
            <div
              className="bg-dark d-flex justify-ceontent-center align-items-center galler1  position-relative "
              data-aos="fade-right"
              onClick={toziaProject}
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">
                  Zia Moheuddin Theatre
                </h5>
              </div>
            </div>
          </div> */}
          {/* <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center "
            data-aos="flip-left"
            onClick={toLKabul}
          >
            <div className="bg-dark d-flex justify-ceontent-center align-items-center galler2 gal2  position-relative">
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">Johnson Controls</h5>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center ">
            <div
              className="bg-dark d-flex justify-ceontent-center align-items-center galler3 gal-6 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">Al Baraka</h5>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center"
            onClick={toDevis}
          >
            <div
              className="bg-dark galler3 gal3 gal-nat-3 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center w-75">
                  Popular Aseptic Packaging Private Ltd.
                </h5>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center  "
            data-aos="fade-right"
            onClick={toTufail}
          >
            <div
              className="galler3 gal-4 gal-nat-4 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">Tufail Chemicals</h5>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center  "
            data-aos="fade-right"
            onClick={toPSO}
          >
            <div
              className="bg-dark galler3 gal-7 gal-nat-7 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">PSO House</h5>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center  "
            data-aos="fade-right"
          >
            <div
              className="bg-dark galler3 gal-7  gal-nat-9 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">Regent Plaza</h5>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-content "
            onClick={toUgrait}
          >
            <div
              className="bg-dark galler1 over1 position-relative "
              data-aos="fade-right"
            >
              <div className="proj-hover-div   d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center w-75 ">
                  Ugarit Pharmaceutical Co Syria
                </h5>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-content"
            onClick={toUnion}
          >
            <div
              className="bg-dark galler3 gal3 over3 gal-nat-3 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">Union Paper Mills</h5>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-content"
            onClick={toZargun}
          >
            <div
              className="bg-dark galler3 gal-6 over6 gal-nat-6 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column  ">
                <h5 className="text-white text-center ">
                  Zarghun Central Gas Processing Facility
                </h5>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center  "
            data-aos="fade-right"
          >
            <div
              className="bg-dark galler3 gal-7  hashi gal-nat-10 position-relative"
              data-aos="fade-left"
            >
              <div className="proj-hover-div  d-flex justify-content-center align-items-center  flex-column">
                <h5 className="text-white text-center ">Hasim Medical</h5>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Proj3;
