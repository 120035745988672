import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

AOS?.init({
  duration: 1000,
  easing: "ease",
  once: true,
});

const AosWrapper = ({ children }) => {

  return <>{children}</>;
};

export default AosWrapper;
