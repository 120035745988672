import React from "react";
import ExpertiesStyledCard from "../Exp_inner_Components/ExpertiesStyledCard";

let whyChooseUsCards = [
  {
    title: "Expertise",
    paragraph:
      " Our technicians undergo rigorous training and stay up-to-date on the latest industry advancements to provide you with the highest level of expertise.",
  },
  {
    title: "Reliability",
    paragraph:
      " We understand the critical importance of your HVACR systems to your operations, which is why we prioritize reliability and responsiveness in everything we do.",
  },
  {
    title: "Exceptional Service",
    paragraph:
      " From initial consultation to ongoing support, we are committed to delivering exceptional service and exceeding your expectations every step of the way.",
  },
  {
    title: "Custom Solutions",
    paragraph:
      " We take the time to understand your unique requirements and develop tailored solutions that address your specific challenges and goals.",
  },
];

const Chiller4 = () => {
  return (
    <div className="container my-5">
      <div className="my-5 py-5 text-center">
        <h2>Why Choose BAC?</h2>
      </div>

      <div className="_chillers_section_4_grid py-2">
        {whyChooseUsCards?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <ExpertiesStyledCard
                title={item?.title}
                paragraph={item?.paragraph}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Chiller4;
