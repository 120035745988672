import React, { useEffect } from "react";
import "./trunkysolutions.css";
import Section2 from "./Section2";
import NavigateBack from "../ExpertiseInner/NavigateBack";
import Footer from "../AboutSec/Footer";
import WhyChooseUs from "../Exp_inner_Components/WhyChooseUs";
import PageDescription from "../Exp_inner_Components/PageDescription";
import PageHeroSection from "../Exp_inner_Components/PageHeroSection";

let cards = [
  {
    title: "Expertise: ",
    paragraph:
      "With a team of seasoned professionals and industry veterans, we possess the expertise to tackle even the most complex HVAC challenges with confidence.",
  },
  {
    title: "Customization: ",
    paragraph: `We understand that every project is unique, which is why we offer tailor-made solutions that are designed to address your specific requirements and preferences.`,
  },
  {
    title: "Quality Assurance: ",
    paragraph: `We are committed to delivering superior quality in every aspect of our services, from product selection to installation and ongoing support.`,
  },
  {
    title: "Reliability:",
    paragraph: `Our track record of successful projects and satisfied clients speaks volumes about our reliability and commitment to excellence.`,
  },
];

function OperationInr() {
  useEffect(() => {
    document.body.classList.add("menu-is-closed");
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavigateBack />

      <PageHeroSection
        parentStyles={" trunky-bg-img "}
        title={`Turnkey Solution`}
        description={`Seamless Turnkey Solutions: From Design to Commissioning`}
      />
      <PageDescription
        title={
          "Welcome to BAC, Your Turnkey Solution for HVACR Chillers and Equipment"
        }
        description={`We understand the pivotal role that HVACR chillers and equipment play in maintaining optimal comfort and efficiency in commercial and industrial settings. With our comprehensive expertise and dedication to excellence, we offer turnkey solutions 
      tailored to meet the diverse needs of our clients.`}
      />
      <Section2 />
      <WhyChooseUs
        title={"Why Choose Us:"}
        title2={"Centric Approach"}
        paragraph={`At BAC, customer satisfaction is our top priority. We strive to build lasting relationships with our clients by providing personalized service and attentive support throughout every stage of the project.`}
        cards={cards}
      />
      <Footer />
    </>
  );
}

export default OperationInr;
