import React from "react";

const PageDescription = ({ title, description, headingStyles }) => {
  return (
    <div className="container mt-lg-5 operatemain _page_description" data-aos='fade-up'>
      <div
        className="row mt-lg-5"
        style={{
          paddingTop: "5rem",
          paddingBottom: "5rem",
        }}
      >
        <div className="col-12 mt-lg-5">
          <h1
            className={`fs-2 fw-bold col-lg-7 mx-auto mb-5 ${headingStyles}`}
            style={{
              lineHeight: "2.5rem",
            }}
          >
            {title}
          </h1>
          <p className="col-lg-10 text-center mx-auto col-sm-12 col-md-12 ">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageDescription;
