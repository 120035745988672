import React from "react";
import img1 from "./images/card-img-1.webp";
import img2 from "./images/card-img-2.webp";
import img3 from "./images/card-img-3.webp";
import img4 from "./images/card-img-4.webp";
import img5 from "./images/card-img-5.webp";

import ImgTextGrid from "../Exp_inner_Components/ImgTextGrid";

let cards = [
  {
    img: img1,
    imgLeft: true,
    title: "Tailored Design",
    paragraph:
      "Our process begins with a comprehensive understanding of your needs and objectives. Our team collaborates closely with you to develop a customized design that aligns with your goals and budget.",
  },
  {
    imgLeft: false,
    img: img2,
    title: "Meticulous Execution",
    paragraph:
      "Once the design is finalized, our skilled technicians, engineers, and project managers take charge of the implementation phase. With meticulous attention to detail, we coordinate all aspects of construction, procurement, and installation to ensure seamless execution.",
  },
  {
    imgLeft: true,
    img: img3,
    title: "Quality Assurance",
    paragraph:
      "We leverage the latest technology and industry best practices to create a comprehensive plan that ensures optimal performance and efficiency. Our commitment to quality means that your project is completed on time and within budget.",
  },
  {
    imgLeft: false,
    img: img4,
    title: "Thorough Commissioning",
    paragraph:
      "Our service doesn't end with installation. We provide comprehensive commissioning services to ensure that your systems are operating at peak performance. Our technicians conduct thorough testing and verification to identify and resolve any issues, ensuring your project meets or exceeds expectations.",
  },
  {
    imgLeft: true,
    img: img5,
    title: "Experience the Difference",
    paragraph:
      "Trust Brothers Air Conditioning to deliver a turnkey solution that exceeds your expectations. Experience the difference of seamless project execution, where optimal performance and timely completion are guaranteed.",
  },
];

function Operate3() {
  return (
    <>
      <div className="container operate3-main my-5">
        <div className="my-5 py-5 text-center">
          <h2>Our Expertise</h2>
        </div>

        <div className="_operation_section_3">
          {cards?.map((item, index) => {
            return <ImgTextGrid key={index} item={item} />;
          })}
        </div>
      </div>
    </>
  );
}

export default Operate3;
