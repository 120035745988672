import React, { useEffect } from "react";
import "./css/Project.css";
import Proj3 from "./Proj3";
import Footer from "../AboutSec/Footer";
import Navbar from "../Navbar";
import "./css/ProductInner.css";
import "./css/Proj_Inner.css";
import Header from "./ReuseableComponents/Header";

function Project() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <Header/>
      <Proj3 />
      <Footer />
    </>
  );
}

export default Project;
