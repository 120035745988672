import React, { useEffect, useState } from "react";
import DashboardContainer from "./DashboardContainer";
import { TextField } from "@mui/material";
import { PutRequest } from "../Actions/Api_Requests";
import { useDispatch } from "react-redux";
import { updateEmployee } from "../../Redux/reducers/DataSlice";
import { RotatingLines } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const EditEmployee = () => {
  const [image, setImage] = useState(null);
  const [currentImg, setCurrentImg] = useState(null);
  const { employees } = useSelector((state) => state.DataSlice);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  let [defaultValues, setDefaultValues] = useState({
    name: "",
    designation: "",
    location: "",
    email: "",
    phone: "",
  });

  const fileOnchange = (e) => {
    setImage(e.target.files[0]);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let { name, designation, email, location, phone } = defaultValues;
      if (!name || !designation || !email || !location || !phone) {
        throw new Error("All fields are required.");
      }

      if (!image && !currentImg) {
        throw new Error("Employee Image is required.");
      }

      let formData = new FormData();
      formData.append("name", defaultValues?.name);
      formData.append("designation", defaultValues?.designation);
      formData.append("email", defaultValues?.email);
      formData.append("location", defaultValues?.location);
      formData.append("phone", defaultValues?.phone);

      if (image) {
        formData.append("file", image);
      }

      let res = await PutRequest(`/api/employe/update/${id}`, formData, "multi");
      dispatch(updateEmployee(res.data.employe));
      window.history.go(-1);

      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      let employe = employees.find((item) => item._id === id);
      setDefaultValues(employe);
      setCurrentImg(employe.image);
    }
  }, [id]);

  return (
    <DashboardContainer title={"Employee Details"}>
      <div className="mt-4">
        <div className="d-flex flex-row flex-wrap align-items-center gap-4">
          <label
            htmlFor="employee-image"
            style={{
              width: "160px",
              overflow: "hidden",
              borderRadius: "5px",
              height: "120px",
              background: "gray",
            }}
          >
            <input
              onChange={fileOnchange}
              style={{ display: "none" }}
              type="file"
              name="file"
              required={!image && defaultValues?.image ? true : false}
              id="employee-image"
            />
            {image ? (
              <img
                alt=""
                src={URL.createObjectURL(image)}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            ) : (
              currentImg?.path && (
                <>
                  <img
                    alt=""
                    src={defaultValues?.image?.path}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </>
              )
            )}
          </label>
          <div className="d-flex flex-row align-items-center gap-3">
            <label
              htmlFor="employee-image"
              className="dash-button dash-button-rounded px-3 bg-steelblue"
            >
              Upload New
            </label>
          </div>
        </div>

        {!image && !currentImg ? (
          <span style={{ fontSize: "12px" }} className="text-danger">
            image is required.
          </span>
        ) : (
          ""
        )}
        <div className="mt-5">
          <form className="" onSubmit={onSubmitHandler}>
            <div className="edit-employee-grid">
              <div className="">
                <TextField
                  multiline
                  rows={1}
                  id="outlined-basic"
                  fullWidth
                  label="Name"
                  variant="outlined"
                  focused
                  value={defaultValues.name}
                  onChange={(e) => {
                    setDefaultValues({
                      ...defaultValues,
                      name: e.target.value,
                    });
                  }}
                />
                <TextField
                  multiline
                  rows={1}
                  id="outlined-basic"
                  fullWidth
                  label="Location"
                  variant="outlined"
                  focused
                  value={defaultValues.location}
                  onChange={(e) => {
                    setDefaultValues({
                      ...defaultValues,
                      location: e.target.value,
                    });
                  }}
                />
                <TextField
                  multiline
                  rows={1}
                  id="outlined-basic"
                  fullWidth
                  label="Telephone Number"
                  variant="outlined"
                  focused
                  value={defaultValues.phone}
                  onChange={(e) => {
                    setDefaultValues({
                      ...defaultValues,
                      phone: e.target.value,
                    });
                  }}
                />
                <TextField
                  multiline
                  rows={1}
                  id="outlined-basic"
                  fullWidth
                  label="Email Address"
                  variant="outlined"
                  type="email"
                  focused
                  value={defaultValues.email}
                  onChange={(e) => {
                    setDefaultValues({
                      ...defaultValues,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="">
                <TextField
                  multiline
                  rows={1}
                  id="outlined-basic"
                  fullWidth
                  label="Designation"
                  variant="outlined"
                  focused
                  value={defaultValues.designation}
                  onChange={(e) => {
                    setDefaultValues({
                      ...defaultValues,
                      designation: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <button
              disabled={loading}
              className="align-self-start mt-4 px-4 dash-button dash-button-rounded bg-steelblue"
              type="submit"
            >
              {loading ? (
                <RotatingLines
                  visible={true}
                  height="20"
                  width="20"
                  color="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default EditEmployee;
