import React, { useEffect, useState } from "react";
import { GetRequest } from "../../../Admin/Actions/Api_Requests";

const EmployeesCards = () => {
  const [employees, setEmployees] = useState([]);

  const fetchEmployees = async () => {
    try {
      let res = await GetRequest("/api/employees");
      setEmployees(res.data.employees);
    } catch (error) {}
  };

  useEffect(() => {
    if (!employees.length) {
      fetchEmployees();
    }
  }, []);

  return (
    <div className="employees-grid-public">
      {employees?.map((item, index) => (
        <div className="rounded employee-grid-item-public" key={index}>
          <img alt="" src={item?.image?.path} />
          <div className="employee-grid-card-footer">
            <h6 className="text-white">{item?.name}</h6>
            <span>{item?.email}</span>
            <span>{item?.designation}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(EmployeesCards);
