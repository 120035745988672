import React from "react";
import CardImg1 from "./images/card-img-1.webp";
import CardImg2 from "./images/card-img-2.webp";
import CardImg3 from "./images/card-img-3.webp";
import CardImg4 from "./images/card-img-4.webp";
import CardImg5 from "./images/card-img-5.webp";
import ImgTextGrid from "../Exp_inner_Components/ImgTextGrid";

let cards = [
  {
    img: CardImg1,
    imgLeft: true,
    title: "Customized Chillers",
    paragraph: `We excel in designing and manufacturing customized chillers tailored to meet the specific requirements of diverse industries. Whether you operate in pharmaceuticals, food and beverage, petrochemicals, or any other sector, we have the expertise to develop chillers that ensure optimal performance and efficiency.`,
  },
  {
    img: CardImg2,
    imgLeft: false,
    title: "Energy Efficiency",
    paragraph: `BAC is at the forefront of energy-efficient cooling solutions. Our team utilizes advanced technologies and engineering practices to design chillers that minimize energy consumption without compromising on performance. By investing in our solutions, clients can significantly reduce operational costs and contribute to sustainability efforts. `,
  },
  {
    img: CardImg3,
    imgLeft: true,
    title: "Reliability and Durability",
    paragraph: `We understand the importance of reliability in industrial cooling applications. Our chillers are built to withstand the rigors of continuous operation in demanding environments. With robust construction and high-quality components, our solutions offer unmatched durability, ensuring uninterrupted cooling for your critical processes.`,
  },
  {
    img: CardImg4,
    imgLeft: false,
    title: "Smart Controls and Monitoring",
    paragraph: `BAC integrates smart controls and monitoring systems into our chillers, allowing for precise temperature regulation and remote management. Through real-time data analytics and predictive maintenance capabilities, clients can optimize performance, prevent downtime, and extend the lifespan of their equipment.
    `,
  },
  {
    img: CardImg5,
    imgLeft: true,
    title: "Comprehensive Support",
    paragraph: `Our commitment to customer satisfaction extends beyond the sale of our products. BAC provides comprehensive support services, including installation, maintenance, and technical assistance. Our team of experts is dedicated to ensuring that your cooling system operates at peak efficiency throughout its lifecycle.
    `,
  },
];

const Section2 = () => {
  return (
    <div className="container my-5">
      <div className="my-5 py-5 text-center">
        <h2>Our Expertise</h2>
      </div>

      <div className="d-flex flex-column" style={{ gap: "5rem" }}>
        {cards?.map((item, index) => {
          return <ImgTextGrid key={index} item={item} />;
        })}
      </div>
    </div>
  );
};

export default Section2;
